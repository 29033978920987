export interface ISubscriptionPlanFeatureViewModel {
  alias: string | null;
  name: string | null;
  description: string | null;
  sortOrder: number | null;
  selected: boolean;
}

export class SubscriptionPlanFeatureViewModel implements ISubscriptionPlanFeatureViewModel {
  alias: string | null = null;
  name: string | null = '';
  description: string | null = null;
  sortOrder: number | null = null;
  selected = false;

  constructor(data?: Partial<ISubscriptionPlanFeatureViewModel>) {
    Object.assign(this, data);
  }
}
