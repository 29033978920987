<accounts-form *ngIf="subscription !== null">
  <accounts-form-section *ngIf="subscription.status !== null">
    <accounts-form-section-title> Subscription status </accounts-form-section-title>
    <accounts-form-section-description> Manage subscription status </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription status
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription status description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select name="Subscription status" [(ngModel)]="subscription.status" bindValue="id" bindLabel="name" [required]="true" placeholder="Subscription status" [options]="subscriptionStatusses"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>
  <accounts-form-section>
    <accounts-form-section-title> Company Details </accounts-form-section-title>
    <accounts-form-section-description> Add the company details for the subscriber </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription Name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Subscription Name'" [(value)]="subscription.name" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Company Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Company Name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Company Name'" [(value)]="subscription.companyName" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            VAT number
          </struct-form-row-headline>
          <struct-form-row-description>
            VAT number description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'VAT number'" [(value)]="subscription.vatNumber" [required]="true" [hintStart]="'Include countrycode ex: DK12345678'"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Postal & city
          </struct-form-row-headline>
          <struct-form-row-description>
            Postal & city description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <struct-input [name]="'Postal'" [(value)]="subscription.postal" [required]="true"></struct-input>
                </div>
                <div class="md:col-span-2">
                  <struct-input [name]="'City'" [(value)]="subscription.city" [required]="true"></struct-input>
                </div>
              </div>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>



    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section>
    <accounts-form-section-title> Subscription owners </accounts-form-section-title>
    <accounts-form-section-description> Manage subscription owners </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription owners
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription owners description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <div *ngIf="getSubscriptionOwners() === null">No owner has been added yet</div>

              <div *ngFor="let owner of getSubscriptionOwners()" class="mt-1 grid grid-cols-12 items-center">
                <span class="col-span-1">
                  <struct-avatar class="pointer-events-none col-span-1 space-x-2 text-left" size="small" name="{{ owner.firstname }} {{ owner.lastname }}"></struct-avatar>
                </span>
                <span class="col-span-5">
                  {{ owner.email }}
                </span>
                <span class="col-span-5 text-left">
                  <!-- <ng-container *ngIf="!owner.emailVerified && !owner.enableResendEmailVerification">
                    <div class="md:w-fit md:bg-blue-200 px-2">Awaiting accept of invite</div>
                  </ng-container>
                  <ng-container *ngIf="owner.enableResendEmailVerification">
                    <struct-button buttonType="button" color="primary" (click)="resendEmailVerification(owner)">Resend verification mail</struct-button>
                  </ng-container> -->
                </span>
                <span class="col-span-1 text-right"> <struct-icon-button (click)="removeFromSubscription(owner)" icon="trash_48"></struct-icon-button></span>
              </div>

              <div class="">
                <struct-button buttonType="button" color="primary" [disabled]="usersNotInSubscription?.length===0" (click)="addSubscriptionOwner()">Add</struct-button>
              </div>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section>
    <accounts-form-section-title> Subscription managers </accounts-form-section-title>
    <accounts-form-section-description> Manage subscription managers </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription managers
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription managers description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <div *ngIf="getSubscriptionManagers() === null">No manager has been added yet</div>
              <div *ngFor="let manager of getSubscriptionManagers()" class="mt-1 grid grid-cols-12 items-center">
                <span class="col-span-1">
                  <struct-avatar class="pointer-events-none col-span-1 space-x-2 text-left" size="small" name="{{ manager.firstname }} {{ manager.lastname }}"></struct-avatar>
                </span>
                <span class="col-span-5">
                  {{ manager.email }}
                </span>
                <span class="col-span-5 text-left">
                  <!-- <ng-container *ngIf="!manager.emailVerified && !manager.enableResendEmailVerification">
                    <div class="md:w-fit md:bg-blue-200 px-2">Awaiting accept of invite</div>
                  </ng-container>
                  <ng-container *ngIf="manager.enableResendEmailVerification">
                    <struct-button buttonType="button" color="primary" (click)="resendEmailVerification(manager)">Resend verification mail</struct-button>
                  </ng-container> -->
                </span>
              </div>
              <div class="">
                <struct-button buttonType="button" color="primary" [disabled]="usersNotInSubscription?.length===0" (click)="addSubscriptionManager()">Add</struct-button>
              </div>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>
</accounts-form>
