import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { UiModule } from '@struct/ui/ui.module';
import { FormControl, NgForm } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StructSpinnerService } from '@struct/ui/index';
import {Subscription, SubscriptionRole} from "@struct/models/accountmanagement/domain/subscriptions";
import {AddSubscriptionToUserCommand} from "@struct/models/accountmanagement/domain/subscriptions/commands";
import { User } from '@struct/models/struct/app/domain/usermanagement/user';

@Component({
  selector: 'accounts-add-user-to-subscription-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsHeaderComponent,
    AccountsFormComponent,
    AccountsFormSectionComponent,
    AccountsFormSectionTitleComponent,
    AccountsFormSectionDescriptionComponent,
    AccountsFormSectionContentComponent,
  ],
  templateUrl: './add-user-to-subscription-dialog.component.html',
  styleUrls: ['./add-user-to-subscription-dialog.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddUserToSubscriptionDialog implements OnDestroy, OnInit {
  @ViewChild('addUserToSubscriptionForm') addUserToSubscriptionForm!: NgForm;
  private destroyed$ = new ReplaySubject();
  showError = false;
  role = '';
  searchControl = new FormControl('');
  filteredUsers: User[] = [];
  addedUsers: User[] = [];
  user: User = new User();
  newUserValid = () => this.isValidEmail() && this.user.firstname !== null && this.user.lastname !== null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { users: User[]; subscription: Subscription; role: SubscriptionRole },
    public dialogRef: MatDialogRef<AddUserToSubscriptionDialog>,
    private spinnerService: StructSpinnerService
  ) {}
  ngOnInit(): void {
    this.role = SubscriptionRole[this.data.role].toLowerCase();
    this.filteredUsers = this.data.users;
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
  addUser(user: User) {
    this.searchControl.setValue('');
    this.data.users = this.data.users.filter(u => u.uid !== user.uid);
    this.filteredUsers = this.data.users;
    this.addedUsers.push(user);
  }
  addNewUser() {
    this.addedUsers.push({ ...this.user });
    this.searchControl.setValue('');
    this.user = new User();
  }
  isNewUser(user: User): boolean {
    return user.uid === '';
  }
  removeUser(user: User) {
    if (user.uid !== '') {
      this.data.users.push(user);
    }

    this.addedUsers = this.addedUsers.filter(u => u.email !== user.email);
    this.filteredUsers = this.data.users;
  }

  saveUsers() {
    this.spinnerService.changeShowSpinner(true);
    this.dialogRef.close(this.addedUsers);
    this.addedUsers.map(u => new AddSubscriptionToUserCommand({ subscriptionUid: this.data.subscription.uid, userUid: u.uid, role: this.data.role }));
  }

  close(): void {
    this.dialogRef.close(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter(event: any) {
    if (typeof event === 'string') {
      const filterValue = event.toLowerCase();
      this.user.email = filterValue;
      this.filteredUsers = this.data.users.filter(
        option => option.firstname.toLowerCase().includes(filterValue) || option.lastname.toLowerCase().includes(filterValue) || option.email.toLowerCase().includes(filterValue)
      );
    }
  }
  shouldAddNewUser(): boolean {
    return this.filteredUsers?.length === 0 && this.isValidEmail();
  }
  isValidEmail(): boolean {
    if (this.user === null) return false;
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.user.email);
  }
}
