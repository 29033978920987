import {  ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { MenuSidebarItem } from './MenuSidebarItem';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { ISidebarMenuComponent } from '../struct-app-sidebar-menu/ISidebarMenuComponent';
import { StructAppSidebarService } from '../struct-app-sidebar.service';
import { LinkSidebarItem } from './LinkSidebarItem';
import { StructAppSidebarPageMenuComponent } from '../struct-app-sidebar-page-menu/struct-app-sidebar-page-menu.component';
import {camelize} from "@struct/utilities/lib/utility";

@Component({
  selector: 'struct-app-sidebar',
  templateUrl: './struct-app-sidebar.component.html',
})
export class StructAppSidebarComponent implements OnInit {
  @Input() sidebarItems: (LinkSidebarItem | MenuSidebarItem)[] = [];
  public menuOffset = 0;
  public currentPageMenuType: ComponentType<ISidebarMenuComponent> | null = null;
  public currentPageMenuIsCollapsible = true;
  public currentOverlayMenuType: ComponentType<ISidebarMenuComponent> | null = null;
  @ViewChild(StructAppSidebarPageMenuComponent) pageMenu!: StructAppSidebarPageMenuComponent;

  constructor(private eRef: ElementRef, private router: Router, private cdr: ChangeDetectorRef, private sidebarService: StructAppSidebarService) {}

  ngOnInit(): void {
    //Set the current page menu
    this.setPageMenu();

    //When navigation changes we need to update the menu
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        //When a navigation ends we always close the menu
        this.closeMenu();
        //We also find the relevant page menu to show
        this.setPageMenu();
      }
    });
  }

  private setPageMenu(): void {
    //Find first sidebar item with a menu that is sticky and matches the current route
    const activePageSidebar = <MenuSidebarItem>this.sidebarItems.find(x => x instanceof MenuSidebarItem && x.menuIsSticky && x.activeOnRoute.some(route => this.doesRouteMatch(route)));
    if (activePageSidebar !== undefined) {
      this.currentPageMenuIsCollapsible = activePageSidebar.menuIsCollapsible;
      if(this.currentPageMenuType !== activePageSidebar.sidebarMenuComponent){        
        this.currentPageMenuType = null;
        this.cdr.detectChanges();
        this.currentPageMenuType = activePageSidebar.sidebarMenuComponent;
        this.cdr.detectChanges();
      }
      this.pageMenu?.collapseIfTemporarilyExpanded();
    } else {
      this.currentPageMenuType = null;
    }
  }

  public isAnyMenuOpen(): boolean {
    return this.currentOverlayMenuType !== null;
  }

  public isMenuOpen(item: MenuSidebarItem | LinkSidebarItem): boolean {
    return item instanceof MenuSidebarItem && this.currentOverlayMenuType === item.sidebarMenuComponent;
  }

  public isRouteActive(item: MenuSidebarItem | LinkSidebarItem): boolean {
    return item.activeOnRoute.some(route => this.doesRouteMatch(route));
  }

  public isMenuItem(item: MenuSidebarItem | LinkSidebarItem): boolean {
    return item instanceof MenuSidebarItem;
  }

  private doesRouteMatch(route: string): boolean {
    return this.router.isActive(route, <IsActiveMatchOptions>{
      matrixParams: 'ignored',
      fragment: 'ignored',
      queryParams: 'ignored',
      paths: 'subset',
    });
  }

  public sidebarItemClicked(elm: LinkSidebarItem | MenuSidebarItem, htmlElement: HTMLAnchorElement): void {
    //If the clicked element is a link, we close the menu and navigate to the route
    if (elm instanceof LinkSidebarItem) {
      this.closeMenu();
      this.router.navigateByUrl(elm.route);
    } else if (elm instanceof MenuSidebarItem) {
      this.menuOffset = htmlElement.offsetTop + 68;
      this.openMenu(elm);
    }
  }

  public openMenu(elm: MenuSidebarItem): void {
    //If the currently open menu item is the same we do nothing
    if (this.currentOverlayMenuType == elm.sidebarMenuComponent) {
      this.closeMenu();
      return;
    }

    //If there is a menu open we close it and open the new one to get a visual effect of closing and opening
    if (this.currentOverlayMenuType !== null) {
      this.currentOverlayMenuType = null;
      this.cdr.detectChanges();
      this.doOpenMenu(elm);
    } else {
      this.doOpenMenu(elm);
    }
  }

  private doOpenMenu(elm: MenuSidebarItem): void {
    //If the menu to open is different from the current page menu we open it
    if (elm.sidebarMenuComponent != this.currentPageMenuType) {
      this.currentOverlayMenuType = elm.sidebarMenuComponent;
    }
    //Otherwise we expand the page menu temporarily untill the user clicks outside the menu
    else {
      if (this.currentPageMenuType == null) {
        return;
      }
      this.pageMenu.expandTemporarily();
    }
  }

  public closeMenu(): void {
    this.currentOverlayMenuType = null;
  }

  protected readonly camelize = camelize;
}
