import {CommonModule} from '@angular/common';
import {Component, OnDestroy, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, RouterModule} from '@angular/router';
import {UiModule} from '@struct/ui/ui.module';
import {ReplaySubject, takeUntil} from 'rxjs';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {SubscriptionPlanDetailsComponent} from '../subscription-plan-details/subscription-plan-details.component';
import {
  StructServicePrice,
  SubscriptionPlan,
  SubscriptionPlanConfiguration
} from "@struct/models/accountmanagement/domain/subscriptions";
import {SubscriptionPlanApiService} from "@struct/services/account-management";
import {CreateSubscriptionPlanCommand} from "@struct/models/accountmanagement/domain/subscriptions/commands";

@Component({
  selector: 'accounts-create-subscription-plan',
  templateUrl: './create-subscription-plan.component.html',
  standalone: true,
  imports: [CommonModule, UiModule, RouterModule, AccountsHeaderComponent, SubscriptionPlanDetailsComponent],
})
export class CreateSubscriptionPlanComponent implements OnDestroy {
  @ViewChild('subscriptionPlanForm') subscriptionPlanForm!: NgForm;
  @ViewChild('subscriptionPlanDetails') subscriptionPlanDetailsComponent!: SubscriptionPlanDetailsComponent;

  private destroyed$ = new ReplaySubject();

  subscriptionPlan: SubscriptionPlan;
  showError = false;

  constructor(private subscriptionPlanApi: SubscriptionPlanApiService, private router: Router) {
    this.subscriptionPlan = new SubscriptionPlan({configuration: new SubscriptionPlanConfiguration({price: new StructServicePrice()})});
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  submitSubscriptionPlan(): void {
    if (!this.subscriptionPlanForm.valid) {
      for (const i in this.subscriptionPlanForm.controls) {
        this.subscriptionPlanForm.controls[i].markAsTouched();
      }
      this.showError = true;
    } else {
      this.subscriptionPlanDetailsComponent.prepareSubscriptionplanForSubmission();
      const cmd = new CreateSubscriptionPlanCommand({
        uid: this.subscriptionPlan.uid,
        name: this.subscriptionPlan.name,
        description: this.subscriptionPlan.description,
        generallyAvailable: this.subscriptionPlan.generallyAvailable,
        sortOrder: this.subscriptionPlan.sortOrder,
        configuration: this.subscriptionPlan.configuration,
      });

      this.subscriptionPlanApi
        .createSubscriptionPlan(cmd)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.router.navigateByUrl('/subscription-plans');
        });
    }
  }
}
