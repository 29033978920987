import {
  IStructServicePrice,
  StructServicePrice
} from "@struct/models/accountmanagement/domain/subscriptions/struct-service-price";

export interface ISubscriptionPlanQuotaViewModel {
  alias: string | null;
  name: string | null;
  description: string | null;
  amountUnit: string | null;
  includedAmount: number | null;
  unitPrice: IStructServicePrice;
  amountPerUnitPrice: number | null;
}

export class SubscriptionPlanQuotaViewModel implements ISubscriptionPlanQuotaViewModel {
  alias: string | null = null;
  name: string | null = null;
  description: string | null = null;
  amountUnit: string | null = null;
  includedAmount: number | null = null;
  unitPrice: IStructServicePrice = new StructServicePrice();
  amountPerUnitPrice: number | null = null;

  constructor(data?: Partial<ISubscriptionPlanQuotaViewModel>) {
    Object.assign(this, data);
  }
}
