// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Invite } from "@struct/models/struct/usermanagementmodule/invite";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationInvite extends Invite {
  organizationUid: string;
}

export class OrganizationInvite extends Invite implements IOrganizationInvite {
  organizationUid = "00000000-0000-0000-0000-000000000000";

  override uid = "00000000-0000-0000-0000-000000000000";

  override email = "";

  override created: Date | string = new Date(0);

  override expires: Date | string = new Date(0);

  override invitedBy = "";

  override inviteToken = "";

  override accepted: boolean | null = null;

  override type = "";


  constructor(data?: Partial<IOrganizationInvite>) {
    super(data);
    Object.assign(this, data);
  }
}