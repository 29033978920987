<div class="flex h-full">
  <div class="w-16 md:w-24 overflow-y-auto bg-gray-200 md:block h-full z-40">
    <div class="flex w-full h-full flex-col items-center">
      <div class="mt-0 w-full flex-1 flex-grow flex-col">

        <a #buttonRef *ngFor="let item of sidebarItems"
          (click)="sidebarItemClicked(item, buttonRef)"
          [ngClass]="[
            isMenuOpen(item) ? 'bg-white text-blue-500' : 'border-transparent',
            isRouteActive(item) && isMenuItem(item) && !isAnyMenuOpen() ? 'bg-gray-100 text-blue-700 border-blue-700 hover:bg-gray-100' : '',
            isRouteActive(item) && !isMenuItem(item) && !isAnyMenuOpen() ? 'bg-gray-50 text-blue-700 border-blue-700 hover:bg-gray-100' : '',
            ]"
          class="group justify-center text-center w-full flex flex-col items-center text-xs text-gray-700 hover:text-gray-800 font-medium relative px-2 py-2 cursor-pointer"
           [attr.data-cy]="camelize(item.label)"
        >

          <struct-icon-button
            class="rounded-full"
            [ngClass]="[isMenuOpen(item) ? 'bg-gray-100 text-blue-700' : 'border-transparent']"
            [icon]="item.icon" iconSize="20"
            [useMaterialIcons]="item.useMaterialIcons"></struct-icon-button>
          <span class="hidden md:block text-xs mt-1">{{ item.label | translate}}</span>
        </a>
      </div>
      <div class="flex-grow-0 w-full flex flex-col">
        <a class="group justify-center text-center w-full flex flex-col items-center text-xs text-gray-700 hover:text-gray-800 font-medium relative px-2 py-2 cursor-pointer">

          <struct-icon-button class="rounded-full" icon="help" iconSize="16"></struct-icon-button>
          <span class="text-xs mt-1">Help</span>
        </a>

      </div>
    </div>
  </div>

  <!--overlay menu-->
  <div class="h-full" *ngIf="currentOverlayMenuType !== null">
    <struct-app-sidebar-menu [component]="currentOverlayMenuType" [yOffset]="menuOffset" (closeMenu)="closeMenu()"></struct-app-sidebar-menu>
  </div>

  <!-- page menu -->
  <div class="h-full" *ngIf="currentPageMenuType !== null">
    <struct-app-sidebar-page-menu [component]="currentPageMenuType" [collapsible]="currentPageMenuIsCollapsible"></struct-app-sidebar-page-menu>
  </div>
</div>
