import { CommonModule } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { UiModule } from '@struct/ui/ui.module';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { SubscriptionDetailsComponent } from '../subscription-details/subscription-details.component';
import {Subscription} from "@struct/models/accountmanagement/domain/subscriptions";
import {SubscriptionApiService} from "@struct/services/account-management";
import {CreateSubscriptionCommand} from "@struct/models/accountmanagement/domain/subscriptions/commands";

@Component({
  selector: 'accounts-create-subscription',
  templateUrl: './create-subscription.component.html',
  standalone: true,
  imports: [CommonModule, UiModule, RouterModule, AccountsHeaderComponent,SubscriptionDetailsComponent],
})
export class CreateSubscriptionComponent implements OnDestroy {
  @ViewChild('subscriptionform') subscriptionForm!: NgForm;

  private destroyed$ = new ReplaySubject();

  showError: boolean;
  subscription: Subscription;

  constructor(private subscriptionApi: SubscriptionApiService, private router: Router) {
    this.showError = false;
    this.subscription = new Subscription({
      paymentCurrency: 'DKK',
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  submitSubscription(): void {
    if (!this.subscriptionForm.valid) {
      for (const i in this.subscriptionForm.controls) {
        this.subscriptionForm.controls[i].markAsTouched();
        this.showError = true;
      }
    } else {
      const command = new CreateSubscriptionCommand({
        uid: this.subscription.uid,
        name: this.subscription.name,
        subscriptionPlanUid: this.subscription.subscriptionPlanUid,
        companyName: this.subscription.companyName,
        vatNumber: this.subscription.vatNumber,
        postal: this.subscription.postal,
        city: this.subscription.city,
        address: this.subscription.address,
        address2: this.subscription.address2,
        country: this.subscription.country,
        contactPerson: this.subscription.contactPerson,
        contactEmail: this.subscription.contactEmail,
        contactPhone: this.subscription.contactPhone,
        firstInvoiceDate: this.subscription.firstInvoiceDate,
        invoiceEmail: this.subscription.invoiceEmail,
        paymentCurrency: this.subscription.paymentCurrency,
      });

      this.subscriptionApi
        .createSubscription(command)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.router.navigateByUrl('/subscriptions');
        });
    }
  }
}
