// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpecialTerms } from "@struct/models/accountmanagement/domain/subscriptions/special-terms";
import { SubscriptionStatus } from "@struct/models/accountmanagement/domain/subscriptions/subscription-status";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscription  {
  uid: string;
  name: string;
  subscriptionPlanUid: string;
  created: Date | string;
  status: SubscriptionStatus;
  companyName: string;
  vatNumber: string;
  postal: string;
  city: string;
  address: string;
  address2?: string | null;
  country: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  invoiceEmail: string;
  paymentCurrency: string;
  firstInvoiceDate: Date | string;
  specialTerms?: SpecialTerms | null;
  partnerUid?: string | null;
}

export class Subscription  implements ISubscription {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  subscriptionPlanUid = "00000000-0000-0000-0000-000000000000";

  created: Date | string = new Date(0);

  status: SubscriptionStatus = SubscriptionStatus.AwaitingApproval;

  companyName = "";

  vatNumber = "";

  postal = "";

  city = "";

  address = "";

  address2: string | null = null;

  country = "";

  contactPerson = "";

  contactPhone = "";

  contactEmail = "";

  invoiceEmail = "";

  paymentCurrency = "";

  firstInvoiceDate: Date | string = new Date(0);

  specialTerms: SpecialTerms | null = null;

  partnerUid: string | null = null;


  constructor(data?: Partial<ISubscription>) {
    Object.assign(this, data);
  }
}