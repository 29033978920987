<struct-app-content-container>
  <accounts-header>Add User</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #addUserToSubscriptionForm="ngForm">
        Add subscription {{ role }}
        <div class="md:grid md:grid-cols-12">
          <div class="md:col-span-12">
            <mat-form-field class="w-full">
              <input type="text" placeholder="search" matInput [formControl]="searchControl" [matAutocomplete]="auto" (ngModelChange)="filter($event)" />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addUser($event.option.value)" [panelWidth]="300">
                <mat-option *ngFor="let user of filteredUsers" [value]="user"> {{ user.firstname }} {{ user.lastname }} ({{ user.email }}) </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <ng-container *ngIf="!shouldAddNewUser(); else newUser">
          <div class="md:grid md:grid-cols-12">
            <div class="md:col-span-12">
              <div *ngFor="let user of addedUsers" class="mt-1 grid grid-cols-12 items-center">
                <span class="col-span-1">
                  <struct-avatar class="pointer-events-none col-span-1 space-x-2 text-left" size="small" name="{{ user.firstname }} {{ user.lastname }}"></struct-avatar>
                </span>
                <span class="col-span-5">
                  {{ user.email }}
                </span>
                <span class="col-span-5 text-left mr-2">
                  <ng-container *ngIf="isNewUser(user)">
                    <div class="md:w-fit md:bg-blue-200 px-2">New struct user</div>
                  </ng-container>
                </span>
                <span class="col-span-1 text-right"> <struct-icon-button (click)="removeUser(user)" icon="trash_48"></struct-icon-button></span>
              </div>
            </div>
          </div>
          <!-- <div class="md:grid md:grid-cols-12 md:gap-6 md:mt-3">
            <div class="md:col-span-12 md:text-right">
              <div *ngIf="showError" class="text-red-400 font-semibold mb-3">Error</div>
              <div class="pt-5 text-right space-x-2">
                <button mat-flat-button (click)="close()" cdkFocusInitial>Cancel</button>
                <struct-button buttonType="button" (click)="saveUsers()" color="primary" [disabled]="addedUsers.length === 0">Add to subscription</struct-button>
              </div>
            </div>
          </div> -->
        </ng-container>
      </form>

      <form #newUserForm="ngForm">
        <ng-template #newUser>
          <div class="md:grid md:grid-cols-6 md:gap-6 md:mt-3">
            <div class="md:col-span-3">
              <struct-input [name]="'First name'" [(value)]="user.firstname" [required]="true"></struct-input>
            </div>
            <div class="md:col-span-3">
              <struct-input [name]="'Last name'" [(value)]="user.lastname" [required]="true"></struct-input>
            </div>
          </div>
          <div class="md:grid md:grid-cols-6 md:gap-6 md:mt-3">
            <div class="md:col-span-6">
              <struct-input [inputType]="'email'" [name]="'Email'" [(value)]="user.email" [required]="true"></struct-input>
            </div>
          </div>
          <div class="md:grid md:grid-cols-12 md:gap-6 md:mt-3">
            <div class="md:col-span-12 md:text-right">
              <div class="pt-5 text-right space-x-2">
                <button mat-flat-button (click)="close()" cdkFocusInitial>Cancel</button>
                <struct-button buttonType="button" (click)="addNewUser()" color="primary" [disabled]="newUserForm.invalid ?? false">Add new user</struct-button>
              </div>
            </div>
          </div>
        </ng-template>
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold mb-3">Error</div>
    <button mat-flat-button (click)="close()" cdkFocusInitial>Cancel</button>
    <struct-button buttonType="button" (click)="saveUsers()" color="primary" [disabled]="addedUsers.length === 0">Add to subscription</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
