import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {PermissionApiService} from "@struct/services/frontend-api";

@Component({
  selector: 'struct-permission-picker',
  templateUrl: './struct-permission-picker.component.html',
  styleUrls: ['./struct-permission-picker.component.scss'],
})
export class StructPermissionPickerComponent {
  @Input() multiple: boolean = false;
  @Input() value: string | null = null;
  @Input() required: boolean = false;
  @Output() valueChange: EventEmitter<string>= new EventEmitter<string>();
  @Input({required:true}) name = 'permission';
  @Input() placeholder: string = 'placeholder';

  permissions : PermissionSearchModel[] = [];

  constructor(private permissionApiService: PermissionApiService, private tr: TranslateService) {   
    const tmp : PermissionSearchModel[] = []; 
      this.permissionApiService.getPermissions().subscribe(x => {
      x.map(p => {
        const name = this.tr.instant(p.nameKey);
        tmp.push(new PermissionSearchModel({ uid: p.uid, permissionName: name}));
      });
      this.permissions = tmp;
    });
  }

  permissionChanged(event: any){
    this.valueChange.emit(event?.toString());
  }
}

export class PermissionSearchModel {
  uid: string = '';
  permissionName: string = '';

  constructor(obj: Partial<PermissionSearchModel>) {
    Object.assign(this, obj);
  }
}