import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Culture } from '@struct/models/struct/app/domain/shared/language/culture';
import { LanguagesApiService } from '@struct/services/frontend-api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'struct-culture-picker',
  templateUrl: './struct-culture-picker.component.html',
  styleUrls: ['./struct-culture-picker.component.scss'],
})
export class StructCulturePickerComponent {
  @Input() multiple: boolean = false;
  @Input() value: string | null = null;
  @Input() required: boolean = false;
  @Output() valueChange: EventEmitter<string>= new EventEmitter<string>();
  @Input({required:true}) name = 'name';
  @Input() placeholder: string = 'placeholder';
  @Input() currentLanguage: number | null = null;
  availableCultures: Culture[] | null = null;

  constructor(private languageApi: LanguagesApiService, cdr: ChangeDetectorRef){
    forkJoin([this.languageApi.getLanguages(), this.languageApi.getCultures()]).subscribe(([languages, cultures]) => {
      let language: any;

      if(this.currentLanguage)
      {
        language = languages.find(l => l.id === this.currentLanguage)
      }

      //Filter available cultures so that we only show cultures that are not already in use
      this.availableCultures = cultures.filter(culture => 
        culture.cultureCode === (language?.cultureCode ?? false) || 
        !languages.some(language => language.cultureCode === culture.cultureCode)
        );
        cdr.detectChanges();
    });
  }

  getFlag(cultureCode: string): string {
    if (cultureCode == null) {
      return '';
    }
    return cultureCode.split('-')[1].toLowerCase();
  }

  cultureChanged(event: any){
    this.valueChange.emit(event?.toString());
  }
}
