import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogData, ConfirmDialogResult, StructConfirmDialogComponent } from '@struct/ui/struct-confirm-dialog/struct-confirm-dialog.component';
import { StructSpinnerService } from '@struct/ui/struct-spinner/struct-spinner.service';
import { UiModule } from '@struct/ui/ui.module';
import { ReplaySubject, of, takeUntil } from 'rxjs';
import { AccountsFormDangerZoneComponent } from '../../accounts-ui/accounts-form/accounts-form-danger-zone/accounts-form-danger-zone.component';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { SubscriptionPlanDetailsComponent } from '../subscription-plan-details/subscription-plan-details.component';
import {
  StructServicePrice,
  SubscriptionPlan,
  SubscriptionPlanConfiguration
} from "@struct/models/accountmanagement/domain/subscriptions";
import {SubscriptionPlanApiService} from "@struct/services/account-management";
import {UpdateSubscriptionPlanCommand} from "@struct/models/accountmanagement/domain/subscriptions/commands";

@Component({
  selector: 'accounts-edit-subscription-plan',
  templateUrl: 'edit-subscription-plan.component.html',
  standalone: true,
  imports: [CommonModule, UiModule, SubscriptionPlanDetailsComponent, AccountsHeaderComponent, AccountsFormDangerZoneComponent],
})
export class EditSubscriptionPlanComponent implements OnInit, OnDestroy {
  @ViewChild('subscriptionPlanform') subscriptionPlanform!: NgForm;
  @ViewChild('subscriptionPlanDetails') subscriptionPlanDetailsComponent!: SubscriptionPlanDetailsComponent;

  private destroyed$ = new ReplaySubject();

  subscriptionPlan: SubscriptionPlan;
  subscriptionPlanName: string | null = null;
  showError = false;

  constructor(
    private subscriptionPlanApi: SubscriptionPlanApiService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private spinnerService: StructSpinnerService,
    public dialog: MatDialog
  ) {
    this.spinnerService.changeShowSpinner(true);
    this.subscriptionPlan = new SubscriptionPlan({
      configuration: new SubscriptionPlanConfiguration({
        availableServices: [],
        price: new StructServicePrice({}),
      }),
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    const uid = this.currentRoute.snapshot.paramMap.get('uid');
    if (uid !== null) {
      this.subscriptionPlanApi
        .getSubscriptionPlan(uid)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(data => {
          this.subscriptionPlan = data;
          this.subscriptionPlanName = this.subscriptionPlan.name;
          this.spinnerService.changeShowSpinner(false);
        });
    }
  }

  deleteSubscriptionPlan(): void {
    const dialogRef = this.dialog.open(StructConfirmDialogComponent, {
      data: <ConfirmDialogData>{
        userValueConfirm: true,
        userValueConfirmString: this.subscriptionPlan?.name,
        description: of('Are you sure you want to delete this struct service?')
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResult) => {
      if (result?.confirmed) {
        const uid = this.currentRoute.snapshot.paramMap.get('uid');
        if (uid !== null) {
          this.subscriptionPlanApi
            .deletedSubscriptionPlan(uid)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
              this.router.navigateByUrl('/subscription-plans');
            });
        }
      }
    });
  }

  submitSubscriptionPlan(): void {
    if (!this.subscriptionPlanform.valid) {
      for (const i in this.subscriptionPlanform.controls) {
        this.subscriptionPlanform.controls[i].markAsTouched();
      }
      this.showError = true;
    } else {
      this.subscriptionPlanDetailsComponent.prepareSubscriptionplanForSubmission();
      const cmd = new UpdateSubscriptionPlanCommand({
        uid: this.subscriptionPlan.uid,
        name: this.subscriptionPlan.name,
        description: this.subscriptionPlan.description,
        generallyAvailable: this.subscriptionPlan.generallyAvailable,
        sortOrder: this.subscriptionPlan.sortOrder,
        configuration: this.subscriptionPlan.configuration,
      });

      this.subscriptionPlanApi
        .updateSubscriptionPlan(cmd)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.router.navigateByUrl('/subscription-plans');
        });
    }
  }
}
