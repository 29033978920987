// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Role } from "@struct/models/struct/app/domain/usermanagement/role";
import { UserGroup } from "@struct/models/struct/app/domain/usermanagement/user-group";
import { UserStatus } from "@struct/models/struct/app/domain/usermanagement/user-status";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUser  {
  uid: string;
  email: string;
  firstname: string;
  lastname: string;
  status: UserStatus;
  userGroupUids: string[];
  roleUids: string[];
  userRoles: Role[];
  userGroups: UserGroup[];
}

export class User  implements IUser {
  uid = "";

  email = "";

  firstname = "";

  lastname = "";

  status: UserStatus = UserStatus.Invited;

  userGroupUids: string[] = [];

  roleUids: string[] = [];

  userRoles: Role[] = [];

  userGroups: UserGroup[] = [];


  constructor(data?: Partial<IUser>) {
    Object.assign(this, data);
  }
}