
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateCatalogueCommand } from "@struct/models/struct/app/domain/catalogues/commands/create-catalogue-command";
import { DeleteCategoriesCommand } from "@struct/models/struct/app/domain/catalogues/commands/delete-categories-command";
import { SetCategoryAttachedAttributesCommand } from "@struct/models/struct/app/domain/catalogues/commands/set-category-attached-attributes-command";
import { SortCataloguesCommand } from "@struct/models/struct/app/domain/catalogues/commands/sort-catalogues-command";
import { UpdateCatalogueCommand } from "@struct/models/struct/app/domain/catalogues/commands/update-catalogue-command";
import { AttributeAttachmentDetail } from "@struct/models/struct/app/domain/catalogues/attribute-attachment-detail";
import { BasicCatalogue } from "@struct/models/struct/app/domain/catalogues/basic-catalogue";
import { Catalogue } from "@struct/models/struct/app/domain/catalogues/catalogue";
import { CatalogueReference } from "@struct/models/struct/app/domain/catalogues/catalogue-reference";
import { Category } from "@struct/models/struct/app/domain/catalogues/category";
import { WizardEditorModel } from "@struct/models/struct/app/domain/dataconfiguration/wizard-editor-model";
import { CategoryEditorModel } from "@struct/models/struct/app/domain/editor/category-editor-model";
import { EditorBreadcrumb } from "@struct/models/struct/app/domain/editor/editor-breadcrumb";
import { CatalogueSearchModel } from "@struct/models/struct/app/frontendapi/models/catalogue/catalogue-search-model";
import { CategoryTree } from "@struct/models/struct/app/frontendapi/models/catalogue/category-tree";
import { CreateCategoryModel } from "@struct/models/struct/app/frontendapi/models/catalogue/create-category-model";
import { SaveTabModel } from "@struct/models/struct/app/frontendapi/models/editormodel/save-tab-model";
import { StructEntityUpdatedModel } from "@struct/models/struct/app/frontendapi/models/editormodel/struct-entity-updated-model";

@Injectable({ providedIn: "root" })
export class CataloguesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getCreateEditorModel(catalogueUid: string): Observable<WizardEditorModel> {
    return this.http.get<WizardEditorModel>(`${this.apiUrl}catalogues/category/create/${catalogueUid}`, this.httpOptions );
  }

  createCategory(model: CreateCategoryModel): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}catalogues/category/create`, model, this.httpOptions);
  }

  deleteCategories(model: DeleteCategoriesCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}catalogues/category/delete`, model, this.httpOptions);
  }

  getCatalogueReferences(): Observable<CatalogueReference[]> {
    return this.http.get<CatalogueReference[]>(`${this.apiUrl}catalogues/cataloguereferences`, this.httpOptions );
  }

  getCatalogueSearchModels(): Observable<CatalogueSearchModel[]> {
    return this.http.get<CatalogueSearchModel[]>(`${this.apiUrl}cataloguesearchmodels`, this.httpOptions );
  }

  getCatalogue(uid: string): Observable<Catalogue> {
    return this.http.get<Catalogue>(`${this.apiUrl}catalogues/${uid}`, this.httpOptions );
  }

  createCatalogue(command: CreateCatalogueCommand): Observable<Catalogue> {
    return this.http.post<Catalogue>(`${this.apiUrl}catalogues`, command, this.httpOptions);
  }

  updateCatalogue(command: UpdateCatalogueCommand): Observable<Catalogue> {
    return this.http.put<Catalogue>(`${this.apiUrl}catalogues`, command, this.httpOptions);
  }

  deleteCatalogue(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}catalogues/${uid}`, this.httpOptions );
  }

  getBasicCatalogues(): Observable<CatalogueReference[]> {
    return this.http.get<CatalogueReference[]>(`${this.apiUrl}catalogues/basiccatalogues`, this.httpOptions );
  }

  getCategoryChildren(categoryId: number): Observable<CategoryTree[]> {
    return this.http.get<CategoryTree[]>(`${this.apiUrl}catalogues/categorychildren?categoryId=${categoryId}`, this.httpOptions );
  }

  getCatalogueChildren(catalogueUid: string): Observable<CategoryTree[]> {
    return this.http.get<CategoryTree[]>(`${this.apiUrl}catalogues/cataloguechildren?catalogueUid=${catalogueUid}`, this.httpOptions );
  }

  getCategory(id: number): Observable<Category> {
    return this.http.get<Category>(`${this.apiUrl}catalogues/category/${id}`, this.httpOptions );
  }

  getCategoryAttributes(id: number): Observable<AttributeAttachmentDetail[]> {
    return this.http.get<AttributeAttachmentDetail[]>(`${this.apiUrl}catalogues/category/${id}/attributes`, this.httpOptions );
  }

  updateCategoryAttributes(command: SetCategoryAttachedAttributesCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}catalogues/category/attributes`, command, this.httpOptions);
  }

  updateCatalogueSorting(command: SortCataloguesCommand): Observable<BasicCatalogue[]> {
    return this.http.post<BasicCatalogue[]>(`${this.apiUrl}catalogues/sorting`, command, this.httpOptions);
  }

  getCategoryBreadcrumb(id: number): Observable<EditorBreadcrumb> {
    return this.http.get<EditorBreadcrumb>(`${this.apiUrl}catalogues/category/breadcrumb/${id}`, this.httpOptions );
  }

  getEditorModel(categoryId: number): Observable<CategoryEditorModel> {
    return this.http.get<CategoryEditorModel>(`${this.apiUrl}catalogues/category/editor/${categoryId}`, this.httpOptions );
  }

  saveTab(model: SaveTabModel): Observable<StructEntityUpdatedModel> {
    return this.http.patch<StructEntityUpdatedModel>(`${this.apiUrl}catalogues/category/savetab`, model, this.httpOptions);
  }
}

