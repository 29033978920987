
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExporterReference } from "@struct/models/struct/app/domain/fileexport/sharedentities/exporter-reference";
import { ExportSupportedField } from "@struct/models/struct/app/domain/filemapping/export-supported-field";
import { ExportTransformation } from "@struct/models/struct/app/domain/filemapping/exporttransformations/export-transformation";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { ExportInBackgroundTaskModel } from "@struct/models/struct/app/frontendapi/models/export/export-in-background-task-model";
import { ExportModel } from "@struct/models/struct/app/frontendapi/models/export/export-model";
import { GlobalListExportSearchModel } from "@struct/models/struct/app/frontendapi/models/export/global-list-export-search-model";
import { ListAttributeField } from "@struct/models/struct/app/frontendapi/models/export/list-attribute-field";
import { ListStringItem } from "@struct/models/struct/app/frontendapi/models/export/list-string-item";
import { SearchModel } from "@struct/models/struct/app/frontendapi/models/search/search-model";

@Injectable({ providedIn: "root" })
export class ExportApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableTransformations(): Observable<{ [key: string]: ExportTransformation[] }> {
    return this.http.get<{ [key: string]: ExportTransformation[] }>(`${this.apiUrl}export/transformations`, this.httpOptions );
  }

  getAvailableTransformationsByMappedFieldUid(mappedFieldUid: string): Observable<ExportTransformation[]> {
    return this.http.get<ExportTransformation[]>(`${this.apiUrl}export/transformations/${mappedFieldUid}`, this.httpOptions );
  }

  getExporters(itemType: StructEntityType): Observable<ExporterReference[]> {
    return this.http.get<ExporterReference[]>(`${this.apiUrl}export/exporters?itemType=${itemType}`, this.httpOptions );
  }

  buildExport(model: ExportModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}export/buildexport`, model, {responseType:'blob' as 'json', withCredentials: true});
  }

  quickExportGlobalList(model: GlobalListExportSearchModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}export/quickexportgloballist`, model, this.httpOptions);
  }

  quickExport(model: SearchModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}export/quickexport`, model, {responseType:'blob' as 'json', withCredentials: true});
  }

  getAvailableFields(itemType: StructEntityType): Observable<ExportSupportedField[]> {
    return this.http.get<ExportSupportedField[]>(`${this.apiUrl}export/availablefields?itemType=${itemType}`, this.httpOptions );
  }

  getAvailableIdentifiers(entityType: StructEntityType): Observable<ListStringItem[]> {
    return this.http.get<ListStringItem[]>(`${this.apiUrl}export/availableidentifiers?entityType=${entityType}`, this.httpOptions );
  }

  getAvailableListAttributeFields(listAttributeUid: string): Observable<ListAttributeField[]> {
    return this.http.get<ListAttributeField[]>(`${this.apiUrl}export/availablelistattributefields?listAttributeUid=${listAttributeUid}`, this.httpOptions );
  }

  exportInBackgroundTask(model: ExportInBackgroundTaskModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/exportinbackgroundtask`, model, this.httpOptions);
  }

  getBackgroundTaskExport(uid: string, exporterReference: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}export/backgroundtaskexport?uid=${uid}&exporterReference=${encodeURIComponent(exporterReference)}`, {responseType:'blob' as 'json', withCredentials: true} );
  }
}

