// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StructServicePrice } from "@struct/models/accountmanagement/domain/subscriptions/struct-service-price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAvailableServiceQuota  {
  alias?: string | null;
  includedAmount: number;
  unitPrice?: StructServicePrice | null;
  amountPerUnitPrice: number;
}

export class AvailableServiceQuota  implements IAvailableServiceQuota {
  alias: string | null = null;

  includedAmount = 0;

  unitPrice: StructServicePrice | null = null;

  amountPerUnitPrice = 0;


  constructor(data?: Partial<IAvailableServiceQuota>) {
    Object.assign(this, data);
  }
}