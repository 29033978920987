
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Dashboard } from "@struct/models/struct/app/domain/dashboards/dashboard";
import { DashboardTab } from "@struct/models/struct/app/domain/dashboards/dashboard-tab";
import { MeasuringPoint } from "@struct/models/struct/app/domain/dashboards/measuring-point";
import { MeasuringPointReference } from "@struct/models/struct/app/domain/dashboards/measuringpoints/measuring-point-reference";
import { MeasuringPointSettingType } from "@struct/models/struct/app/domain/dashboards/measuringpoints/measuring-point-setting-type";
import { Widget } from "@struct/models/struct/app/domain/dashboards/widget";
import { WidgetType } from "@struct/models/struct/app/domain/dashboards/widget-type";
import { DistributionChartWidget } from "@struct/models/struct/app/domain/dashboards/widgets/distribution-chart-widget";
import { EnrichmentInsightWidget } from "@struct/models/struct/app/domain/dashboards/widgets/enrichment-insight-widget";
import { SearchResultWidget } from "@struct/models/struct/app/domain/dashboards/widgets/search-result-widget";
import { EnrichmentInsightSearchUrlRequest } from "@struct/models/struct/app/frontendapi/models/enrichmentinsight/enrichment-insight-search-url-request";

@Injectable({ providedIn: "root" })
export class DashboardsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getDashboard(): Observable<Dashboard> {
    return this.http.get<Dashboard>(`${this.apiUrl}dashboards/getdashboard`, this.httpOptions );
  }

  getDashboardSetup(): Observable<Dashboard> {
    return this.http.get<Dashboard>(`${this.apiUrl}dashboards/getdashboardsetup`, this.httpOptions );
  }

  getDefaultDashboard(): Observable<Dashboard> {
    return this.http.get<Dashboard>(`${this.apiUrl}dashboards/getdefaultdashboard`, this.httpOptions );
  }

  buildExport(measuringPointUid: string, fileMappingUid: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}dashboards/buildexport?measuringPointUid=${measuringPointUid}&fileMappingUid=${fileMappingUid}`, {responseType:'blob' as 'json', withCredentials: true} );
  }

  saveDashboard(dashboard: Dashboard): Observable<Dashboard> {
    return this.http.post<Dashboard>(`${this.apiUrl}dashboards/savedashboard`, dashboard, this.httpOptions);
  }

  saveDefaultDashboard(dashboard: Dashboard): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}dashboards/savedefaultdashboard`, dashboard, this.httpOptions);
  }

  getWidgetTypes(): Observable<WidgetType[]> {
    return this.http.get<WidgetType[]>(`${this.apiUrl}dashboards/getwidgettypes`, this.httpOptions );
  }

  hydrateWidget(widget: Widget): Observable<Widget> {
    return this.http.post<Widget>(`${this.apiUrl}dashboards/hydratewidget`, widget, this.httpOptions);
  }

  hydrateTab(tab: DashboardTab): Observable<DashboardTab> {
    return this.http.post<DashboardTab>(`${this.apiUrl}dashboards/hydratetab`, tab, this.httpOptions);
  }

  getMeasuringPoints(): Observable<MeasuringPoint[]> {
    return this.http.get<MeasuringPoint[]>(`${this.apiUrl}dashboards/getmeasuringpoints`, this.httpOptions );
  }

  getMeasuringPointSettingTypes(): Observable<MeasuringPointSettingType[]> {
    return this.http.get<MeasuringPointSettingType[]>(`${this.apiUrl}dashboards/getmeasuringpointsettingtypes`, this.httpOptions );
  }

  createMeasuringPoint(measuringPoint: MeasuringPoint): Observable<MeasuringPoint> {
    return this.http.post<MeasuringPoint>(`${this.apiUrl}dashboards/createmeasuringpoint`, measuringPoint, this.httpOptions);
  }

  updateMeasuringPoint(measuringPoint: MeasuringPoint): Observable<MeasuringPoint> {
    return this.http.post<MeasuringPoint>(`${this.apiUrl}dashboards/updatemeasuringpoint`, measuringPoint, this.httpOptions);
  }

  getMeasuringPoint(measuringPointUid: string): Observable<MeasuringPoint> {
    return this.http.get<MeasuringPoint>(`${this.apiUrl}dashboards/getmeasuringpoint?measuringPointUid=${measuringPointUid}`, this.httpOptions );
  }

  getMeasuringPointReference(measuringPointUid: string): Observable<MeasuringPointReference> {
    return this.http.get<MeasuringPointReference>(`${this.apiUrl}dashboards/measuringpointreference?measuringPointUid=${measuringPointUid}`, this.httpOptions );
  }

  getEnrichmentInsightSearchUrl(model: EnrichmentInsightSearchUrlRequest): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}dashboards/getenrichmentinsightsearchurl`, model, this.httpOptions);
  }

  hydrateSearchResultWidget(widget: SearchResultWidget): Observable<SearchResultWidget> {
    return this.http.get<SearchResultWidget>(`${this.apiUrl}dashboards/hydratesearchresultwidget`, this.httpOptions );
  }

  refreshDistributionChart(widget: DistributionChartWidget): Observable<SearchResultWidget> {
    return this.http.post<SearchResultWidget>(`${this.apiUrl}dashboards/refreshdistributionchart`, widget, this.httpOptions);
  }

  refreshEnrichmentInsightWidget(widget: EnrichmentInsightWidget): Observable<SearchResultWidget> {
    return this.http.post<SearchResultWidget>(`${this.apiUrl}dashboards/refreshenrichmentinsightwidget`, widget, this.httpOptions);
  }

  getUpdatedSearchResult(measuringPointUid: string): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}dashboards/getupdatedsearchresult?measuringPointUid=${measuringPointUid}`, null, this.httpOptions);
  }

  getSharedTabs(): Observable<DashboardTab[]> {
    return this.http.get<DashboardTab[]>(`${this.apiUrl}dashboards/getsharedtabs`, this.httpOptions );
  }

  createDashboardTab(tab: DashboardTab): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}dashboards/tabs`, tab, this.httpOptions);
  }

  updateDashboardTab(tab: DashboardTab): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}dashboards/tabs`, tab, this.httpOptions);
  }

  getDashboardTab(uid: string): Observable<DashboardTab> {
    return this.http.get<DashboardTab>(`${this.apiUrl}dashboards/tabs/${uid}`, this.httpOptions );
  }

  deleteDashboardTab(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}dashboards/tabs/${uid}`, this.httpOptions );
  }

  deleteMeasuringPoint(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}dashboards/deletemeasuringpoint?uid=${uid}`, this.httpOptions );
  }
}

