
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateFileMappingCommand } from "@struct/models/struct/app/domain/filemapping/commands/create-file-mapping-command";
import { UpdateFileMappingCommand } from "@struct/models/struct/app/domain/filemapping/commands/update-file-mapping-command";
import { FileMapping } from "@struct/models/struct/app/domain/filemapping/file-mapping";
import { ImportSupportedField } from "@struct/models/struct/app/domain/filemapping/import-supported-field";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { ExportImportFieldModel } from "@struct/models/struct/app/frontendapi/models/filemapping/export-import-field-model";

@Injectable({ providedIn: "root" })
export class FileMappingsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  createFileMapping(cmd: CreateFileMappingCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}filemappings`, cmd, this.httpOptions);
  }

  getFileMappingByUid(uid: string): Observable<FileMapping | null> {
    return this.http.get<FileMapping | null>(`${this.apiUrl}filemappings/${uid}`, this.httpOptions );
  }

  getAllFileMappings(): Observable<FileMapping[]> {
    return this.http.get<FileMapping[]>(`${this.apiUrl}filemappings`, this.httpOptions );
  }

  getMyImportFileMappings(): Observable<FileMapping[]> {
    return this.http.get<FileMapping[]>(`${this.apiUrl}filemappings/import`, this.httpOptions );
  }

  getMyExportFileMappings(): Observable<FileMapping[]> {
    return this.http.get<FileMapping[]>(`${this.apiUrl}filemappings/export`, this.httpOptions );
  }

  copyFileMapping(uid: string, newName: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}filemappings/copy/${uid}`, newName, this.httpOptions);
  }

  updateFileMapping(cmd: UpdateFileMappingCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}filemappings`, cmd, this.httpOptions);
  }

  deleteFileMapping(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}filemappings/${uid}`, this.httpOptions );
  }

  getAvailableImportFieldsByExportDescriptors(itemType: StructEntityType): Observable<ImportSupportedField[]> {
    return this.http.get<ImportSupportedField[]>(`${this.apiUrl}filemappings/available-import-fields/${itemType}`, this.httpOptions );
  }

  getAvailableExportImportFieldsByExportDescriptors(itemType: StructEntityType): Observable<ExportImportFieldModel[]> {
    return this.http.get<ExportImportFieldModel[]>(`${this.apiUrl}filemappings/available-export-fields/${itemType}`, this.httpOptions );
  }
}

