
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssignUserToUserGroupCommand } from "@struct/models/struct/app/domain/usermanagement/commands/assign-user-to-user-group-command";
import { CreateUserGroupCommand } from "@struct/models/struct/app/domain/usermanagement/commands/create-user-group-command";
import { UnassignUserFromUserGroupCommand } from "@struct/models/struct/app/domain/usermanagement/commands/unassign-user-from-user-group-command";
import { UpdateUserGroupCommand } from "@struct/models/struct/app/domain/usermanagement/commands/update-user-group-command";
import { UserGroup } from "@struct/models/struct/app/domain/usermanagement/user-group";
import { UserGroupSearchModel } from "@struct/models/struct/app/frontendapi/models/usergroups/user-group-search-model";

@Injectable({ providedIn: "root" })
export class UserGroupsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getUserGroups(): Observable<UserGroupSearchModel[]> {
    return this.http.get<UserGroupSearchModel[]>(`${this.apiUrl}usergroups`, this.httpOptions );
  }

  getUserGroup(uid: string): Observable<UserGroup> {
    return this.http.get<UserGroup>(`${this.apiUrl}usergroups/${uid}`, this.httpOptions );
  }

  createUserGroup(cmd: CreateUserGroupCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usergroups`, cmd, this.httpOptions);
  }

  updateUserGroup(cmd: UpdateUserGroupCommand): Observable<UserGroup> {
    return this.http.put<UserGroup>(`${this.apiUrl}usergroups`, cmd, this.httpOptions);
  }

  deleteUserGroup(uid: string): Observable<number> {
    return this.http.delete<number>(`${this.apiUrl}usergroups/${uid}`, this.httpOptions );
  }

  assignUsersToUserGroup(cmd: AssignUserToUserGroupCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usergroups/assignusers`, cmd, this.httpOptions);
  }

  unassignUsersFromUserGroup(cmd: UnassignUserFromUserGroupCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usergroups/unassignusers`, cmd, this.httpOptions);
  }
}

