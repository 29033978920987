// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum UserStatus {
  Invited = "Invited",
  Active = "Active",
  Disabled = "Disabled",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace UserStatus {
  export function toString(value: UserStatus): string {
    return UserStatus[value];
  }

  export const values = (): UserStatus[] => [
    UserStatus.Invited,
    UserStatus.Active,
    UserStatus.Disabled,
  ];

  export function parse(value: string, ignoreCase?: boolean): UserStatus | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'UserStatus';
  }
}
