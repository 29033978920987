
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SignInRequest } from "@struct/models/struct/authenticationmodule/models/sign-in-request";
import { SignInResponse } from "@struct/models/struct/authenticationmodule/models/sign-in-response";
import { UserModel } from "@struct/models/struct/authenticationmodule/models/user-model";
import { ForgotPasswordCommand } from "@struct/models/struct/usermanagementmodule/commands/forgot-password-command";
import { ResetPasswordCommand } from "@struct/models/struct/usermanagementmodule/commands/reset-password-command";
import { CommandResponse } from "@struct/models/struct/shared/models/command-response";

@Injectable({ providedIn: "root" })
export class AuthenticationApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getAntiforgeryToken(): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}auth/antiforgery`, this.httpOptions );
  }

  signInWithMicrosoft(): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(`${this.apiUrl}auth/signinwithmicrosoft`, null, this.httpOptions);
  }

  signIn(signInRequest: SignInRequest): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(`${this.apiUrl}auth/signin`, signInRequest, this.httpOptions);
  }

  getUser(): Observable<UserModel | null> {
    return this.http.get<UserModel | null>(`${this.apiUrl}auth/user`, this.httpOptions );
  }

  signOut(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}auth/signout`, null, this.httpOptions);
  }

  forgotPassword(command: ForgotPasswordCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}account/forgotpassword`, command, this.httpOptions);
  }

  resetPassword(command: ResetPasswordCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}account/resetpassword`, command, this.httpOptions);
  }

  canAccessCurrentTenant(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}auth/canAccessTenant`, this.httpOptions );
  }
}

