import { ComponentType } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicDirective } from '../../directives/dynamicHost.directive';
import { ISidebarMenuComponent } from './ISidebarMenuComponent';

@Component({
  selector: 'struct-app-sidebar-menu',
  templateUrl: './struct-app-sidebar-menu.component.html',
})
export class StructAppSidebarMenuComponent implements OnInit {
  @Input() component: ComponentType<ISidebarMenuComponent> | null = null;
  @Input() yOffset = 49;
  @Output() closeMenu = new EventEmitter<void>();
  @ViewChild(DynamicDirective, { static: true }) structDynamicHost!: DynamicDirective;
  public menuTitle = '';
  public menuComponent: ISidebarMenuComponent | null = null;


  ngOnInit(): void {
    if (this.component == null) {
      throw new Error('A component to show must be provided to open the menu');
    }

    const viewContainerRef = this.structDynamicHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<ISidebarMenuComponent>(this.component);
    this.menuComponent = componentRef.instance;
    this.menuTitle = componentRef.instance.menuTitle;
  }

  public close(): void {
    this.closeMenu.emit();
  }
}
