// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AvailableServiceQuota } from "@struct/models/accountmanagement/domain/subscriptions/available-service-quota";
import { StructServicePrice } from "@struct/models/accountmanagement/domain/subscriptions/struct-service-price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAvailableStructService  {
  alias?: string | null;
  price?: StructServicePrice | null;
  activeFeatures: string[];
  quotas: AvailableServiceQuota[];
}

export class AvailableStructService  implements IAvailableStructService {
  alias: string | null = null;

  price: StructServicePrice | null = null;

  activeFeatures: string[] = [];

  quotas: AvailableServiceQuota[] = [];


  constructor(data?: Partial<IAvailableStructService>) {
    Object.assign(this, data);
  }
}