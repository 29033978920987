// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionPlanConfiguration } from "@struct/models/accountmanagement/domain/subscriptions/subscription-plan-configuration";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscriptionPlan  {
  uid: string;
  name: string;
  description?: string | null;
  generallyAvailable: boolean;
  sortOrder: number;
  configuration: SubscriptionPlanConfiguration;
}

export class SubscriptionPlan  implements ISubscriptionPlan {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  description: string | null = null;

  generallyAvailable = false;

  sortOrder = 0;

  configuration: SubscriptionPlanConfiguration = new SubscriptionPlanConfiguration();


  constructor(data?: Partial<ISubscriptionPlan>) {
    Object.assign(this, data);
  }
}