import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageSearchModel } from '@struct/models/struct/app/frontendapi/models/language/language-search-model';
import { LanguagesApiService } from "@struct/services/frontend-api";

@Component({
  selector: 'struct-language-picker',
  templateUrl: './struct-language-picker.component.html',
  styleUrls: ['./struct-language-picker.component.scss'],
})
export class StructLanguagePickerComponent {
  @Input() multiple: boolean = false;
  @Input() value: any | null = null;
  @Input() required: boolean = false;
  @Output() valueChange = new EventEmitter();
  @Input() loading: boolean = true;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Input({required: true}) name = 'language';
  @Input() placeholder: string = 'placeholder';

  languages : LanguageSearchModel[] = [];

  constructor(private languagesApiService: LanguagesApiService, cdr: ChangeDetectorRef){

    languagesApiService.getLanguages().subscribe(x => {
      this.languages = x ?? [];
      cdr.detectChanges();
      this.loadingChange.emit(false);
    });

  }

  languageChanged(event: any){
    this.valueChange.emit(event);
  }
}



