import { SubscriptionPlanFeatureViewModel } from './SubscriptionPlanFeatureViewModel';
import { SubscriptionPlanQuotaViewModel } from './SubscriptionPlanQuotaViewModel';
import {
  IStructServicePrice,
  StructServicePrice
} from "@struct/models/accountmanagement/domain/subscriptions/struct-service-price";

export interface ISubscriptionPlanServiceViewModel {
  alias: string | null;
  name: string | null;
  description: string | null;
  available: boolean;
  features: SubscriptionPlanFeatureViewModel[];
  quotas: SubscriptionPlanQuotaViewModel[];
  price: IStructServicePrice;
}

export class SubscriptionPlanServiceViewModel implements ISubscriptionPlanServiceViewModel {
  alias: string | null = null;
  name: string | null = null;
  description: string | null = null;
  available = false;
  features: SubscriptionPlanFeatureViewModel[] = [];
  quotas: SubscriptionPlanQuotaViewModel[] = [];
  price: IStructServicePrice = new StructServicePrice();

  constructor(data?: Partial<ISubscriptionPlanServiceViewModel>) {
    Object.assign(this, data);
  }
}
