<div class="grid gap-2 @[400px]/workarea:gap-x-4" [ngClass]="stack ? 'grid-cols-1' : 'grid-cols-12'">
    <div class="col-span-12 @4xl/workarea:col-span-4 @8xl/workarea:col-span-3 @12xl/workarea:col-span-2" *ngIf="!hideTitleAndDescription">
        <div class="py-2">
            <h3 class="text-base font-medium leading-6 text-gray-800 flex items-center space-x-2">
                <ng-content select="struct-form-row-headline"></ng-content>
                <struct-icon icon="info" size="12" class="text-gray-500" (click)="hideDescription = !hideDescription"
                  *ngIf="hideDescription"
                  #tooltip="matTooltip"
                  matTooltip="Show description"
                  [matTooltipPosition]="'above'">
                </struct-icon>
            </h3>
            <p class="mt-1 text-sm text-gray-600" *ngIf="!hideDescription" [ngClass]="hideDescription ? '' : 'animate-in slide-in-from-bottom-2'">
                <ng-content select="struct-form-row-description"></ng-content>
            </p>
        </div>
    </div>
    <div class="col-span-12" [ngClass]="hideTitleAndDescription ? '' : '@4xl/workarea:col-span-8 @8xl/workarea:col-span-9 @12xl/workarea:col-span-10'">
        <ng-content></ng-content>
    </div>
</div>

<hr *ngIf="borderBelow" class="border-gray-100 my-5" />
<div *ngIf="marginBelow && !borderBelow" class="mb-5"></div>
