<accounts-form>
  <accounts-form-section>
    <accounts-form-section-title>
      General information
    </accounts-form-section-title>
    <accounts-form-section-description>
      Add overall information on the subscription plan
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription plan name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Name'" [(value)]="subscriptionPlan.name" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Description
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription plan description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-textarea [name]="'Description'" [(value)]="subscriptionPlan.description"></struct-textarea>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Sort order
          </struct-form-row-headline>
          <struct-form-row-description>
            Sort order description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [inputType]="'number'" [name]="'Sort order'" [(value)]="subscriptionPlan.sortOrder" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Generally available
          </struct-form-row-headline>
          <struct-form-row-description>
            Generally available
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-checkbox [(value)]="subscriptionPlan.generallyAvailable">Generally available</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section>
    <accounts-form-section-title>
      Price information
    </accounts-form-section-title>
    <accounts-form-section-description>
      Set the pricing for this subscription plan
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Price
          </struct-form-row-headline>
          <struct-form-row-description>
            Price description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.configuration!.price!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.configuration!.price!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.configuration!.price!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section>
    <accounts-form-section-title>
      Service details
    </accounts-form-section-title>
    <accounts-form-section-description>
      Select the services, features and quotas available in this subscription plan
    </accounts-form-section-description>
    <accounts-form-section-content>
      <div *ngFor="let structService of serviceViewModels; let first = first; let last = last;" class="relative border border-gray-100 p-4 cursor-pointer focus:outline-none" [ngClass]="{
        'rounded-tl-md rounded-tr-md' : first,
        'rounded-bl-md rounded-br-md' : last,
        'bg-blue-50/50 border-blue-200 z-10' : structService.available
    }">
        <div class="flex">
          <struct-checkbox [(value)]="structService.available"></struct-checkbox>
          <span class="ml-3 flex flex-col" (click)="toggleService(structService)">
            <span class="block text-lg font-medium" [ngClass]="{'text-blue-900' : structService.available}">
              {{structService.name}}
            </span>
            <span class="block text-sm" [ngClass]="{'text-blue-700' : structService.available}">
              {{structService.description}}
            </span>
          </span>
        </div>

        <div *ngIf="structService.available" class="bg-white p-5 mt-5 border border-blue-100">

          <span class="block text-md font-medium text-gray-700 pb-5">Set a price if this service is optional and has an extra cost associated</span>
          <div class="grid grid-cols-3 gap-6">

            <div class="col-span-1">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price (DKK)'" [(value)]="structService.price.priceDKK" [required]="true">
                <struct-input-suffix><span class="">DKK</span></struct-input-suffix>
              </struct-input>
            </div>

            <div class="col-span-1">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price (EUR)'" [(value)]="structService.price.priceEUR" [required]="true">
                <struct-input-suffix><span class="">EUR</span></struct-input-suffix>
              </struct-input>
            </div>

            <div class="col-span-1">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price (USD)'" [(value)]="structService.price.priceUSD" [required]="true">
                <struct-input-suffix><span class="">USD</span></struct-input-suffix>
              </struct-input>
            </div>

          </div>

          <div class="hidden sm:block" aria-hidden="true">
            <div class="pt-5 pb-0">
              <div class="border-t border-gray-100"></div>
            </div>
          </div>

          <span *ngIf="structService.features.length > 0" class="block text-md font-medium text-gray-700 py-5">Select available features</span>
          <div *ngFor="let feature of structService.features">
            <div class="grid grid-cols-3 py-2">
              <div class="col-span-2">
                <div>
                  <span>{{feature.name}}</span>
                </div>
                <div>
                  <small>{{feature.description}}</small>
                </div>
              </div>
              <div class="col-span-1">
                <struct-checkbox [(value)]="feature.selected"></struct-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="structService.features.length > 0" class="hidden sm:block" aria-hidden="true">
            <div class="pt-5 pb-0">
              <div class="border-t border-gray-100"></div>
            </div>
          </div>

          <span *ngIf="structService.quotas.length > 0" class="block text-md font-medium text-gray-700 py-5">Select available quotas</span>
          <div *ngFor="let quota of structService.quotas" class="pb-6">
            <div class="grid grid-cols-3 py-2">
              <div class="col-span-1">
                <div>
                  <span>{{quota.name}}</span>
                </div>
                <div>
                  <small>{{quota.description}}</small>
                </div>
              </div>
              <div class="col-span-2">

                <div class="grid grid-cols-2 gap-6 pb-6">
                  <div class="col-span-1">
                    <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included'" [(value)]="quota.includedAmount" [required]="true">
                      <struct-input-suffix><span class="">{{quota.amountUnit}}</span></struct-input-suffix>
                    </struct-input>
                  </div>

                  <div class="col-span-1">
                    <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Units per extra price'" [(value)]="quota.amountPerUnitPrice" [required]="true">
                      <struct-input-suffix><span class="">{{quota.amountUnit}}</span></struct-input-suffix>
                    </struct-input>
                  </div>
                </div>

                <div class="grid grid-cols-3 gap-6">

                  <div class="col-span-1">
                    <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price extra'" [(value)]="quota.unitPrice.priceDKK" [required]="true">
                      <struct-input-suffix><span class="">DKK</span></struct-input-suffix>
                    </struct-input>
                  </div>

                  <div class="col-span-1">
                    <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price extra'" [(value)]="quota.unitPrice.priceEUR" [required]="true">
                      <struct-input-suffix><span class="">EUR</span></struct-input-suffix>
                    </struct-input>
                  </div>

                  <div class="col-span-1">
                    <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price extra'" [(value)]="quota.unitPrice.priceUSD" [required]="true">
                      <struct-input-suffix><span class="">USD</span></struct-input-suffix>
                    </struct-input>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </accounts-form-section-content>
  </accounts-form-section>

</accounts-form>
