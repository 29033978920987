import { Component } from '@angular/core';
import { StructColumnHeaderBaseComponent } from '../struct-column-header-base';
import { FieldQuery, NumberFieldQuery, NumberQueryOperator } from '@struct/models/struct/shared/search/datafieldqueries';
@Component({
  selector: 'struct-number-header',
  templateUrl: './struct-number-header.component.html',
})
export class StructNumberHeaderComponent extends StructColumnHeaderBaseComponent {
  getFieldQuery(str : string | null | undefined) : FieldQuery | null {
    if (str === null || str === undefined || str.length === 0) {
      return null;
    }
    
    const queryOperator = this.getQueryOperator(str);
    return new NumberFieldQuery({
      fieldUid: this.column.id,
      queryOperator: queryOperator,
      filterValue: this.getCleanSearchValue(str, queryOperator),
    });
  }

  getQueryOperator(str: string): NumberQueryOperator {
    if(str === null || str === undefined || str === "")
    {
      return NumberQueryOperator.Equals;
    }
    if(str.startsWith("=")){
      return NumberQueryOperator.Equals;
    }
    if(str.startsWith("!\"\"")){
      return NumberQueryOperator.IsNotEmpty;
    }
    if(str.startsWith("\"\"")){
      return NumberQueryOperator.IsEmpty;
    }    
    if(str.startsWith("!") || str.startsWith("!=")){
      return NumberQueryOperator.NotEquals;
    }
    if(str.startsWith("<=")){
      return NumberQueryOperator.SmallerThanOrEqualTo;
    }    
    if(str.startsWith(">=")){
      return NumberQueryOperator.LargerThanOrEqualTo;
    }
    if(str.startsWith("<")){
      return NumberQueryOperator.SmallerThan;
    }    
    if(str.startsWith(">")){
      return NumberQueryOperator.LargerThan;
    }
    return NumberQueryOperator.Equals;
  }

  getUiText(query: FieldQuery){
    const typed = query as NumberFieldQuery;
    let prefix = "";
    if(typed.queryOperator === NumberQueryOperator.NotEquals){
      prefix = "!";
    }
    else if(typed.queryOperator === NumberQueryOperator.LargerThan){
      prefix = ">";
    }
    else if(typed.queryOperator === NumberQueryOperator.LargerThanOrEqualTo){
      prefix = ">=";
    }
    else if(typed.queryOperator === NumberQueryOperator.IsEmpty){
      prefix = "\"\"";
    }
    else if(typed.queryOperator === NumberQueryOperator.IsNotEmpty){
      prefix = "!\"\"";
    }
    else if(typed.queryOperator === NumberQueryOperator.SmallerThan){
      prefix = "<";
    }
    else if(typed.queryOperator === NumberQueryOperator.SmallerThanOrEqualTo){
      prefix = "<=";
    }
    return prefix + typed.filterValue;
  }

  getCleanSearchValue(str: string, operator: NumberQueryOperator) : number {
    //Remove first character
    if(operator === NumberQueryOperator.LargerThan || operator === NumberQueryOperator.SmallerThan){
      return Number.parseFloat(str.substring(1, str.length));
    }
    //Remove first two characters
    if(operator === NumberQueryOperator.LargerThanOrEqualTo || operator === NumberQueryOperator.SmallerThanOrEqualTo || operator === NumberQueryOperator.IsEmpty)
    {
      return Number.parseFloat(str.substring(2, str.length));
    }
    //Remove first two characters
    if(operator === NumberQueryOperator.IsNotEmpty)
    {
      return Number.parseFloat(str.substring(3, str.length));
    }
    if(operator === NumberQueryOperator.Equals && str.startsWith("=")){
      return Number.parseFloat(str.substring(1, str.length));
    }
    if(operator === NumberQueryOperator.NotEquals && str.startsWith("!=")){
      return Number.parseFloat(str.substring(2, str.length));
    }
    if(operator === NumberQueryOperator.NotEquals && str.startsWith("!")){
      return Number.parseFloat(str.substring(1, str.length));
    }
    return Number.parseFloat(str);
  }
}
