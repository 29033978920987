import {
  Subscription,
  SubscriptionPlan,
  SubscriptionStatus
} from "@struct/models/accountmanagement/domain/subscriptions";

export class SubscriptionModel
{
    uid : string | null = null;
    name : string | null = null;
    created :  Date| string | number  = '';
    status : SubscriptionStatus| null = null;
    subscriptionPlan : SubscriptionPlan | null = null;

    constructor (entity : Subscription, plan : SubscriptionPlan){
        this.uid = entity.uid;
        this.name = entity.name;
        this.created = entity.created;
        this.status = entity.status;
        this.subscriptionPlan = plan;
    }
}
