
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddSubscriptionToUserCommand } from "@struct/models/accountmanagement/domain/subscriptions/commands/add-subscription-to-user-command";
import { CreateSubscriptionCommand } from "@struct/models/accountmanagement/domain/subscriptions/commands/create-subscription-command";
import { RemoveSubscriptionToUserCommand } from "@struct/models/accountmanagement/domain/subscriptions/commands/remove-subscription-to-user-command";
import { UpdateSubscriptionCommand } from "@struct/models/accountmanagement/domain/subscriptions/commands/update-subscription-command";
import { Subscription } from "@struct/models/accountmanagement/domain/subscriptions/subscription";
import { UserSubscription } from "@struct/models/accountmanagement/domain/subscriptions/user-subscription";

@Injectable({ providedIn: "root" })
export class SubscriptionApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.apiUrl}subscriptions`, this.httpOptions );
  }

  getSubscription(uid: string): Observable<Subscription> {
    return this.http.get<Subscription>(`${this.apiUrl}subscriptions/${uid}`, this.httpOptions );
  }

  createSubscription(cmd: CreateSubscriptionCommand | null): Observable<Subscription> {
    return this.http.post<Subscription>(`${this.apiUrl}subscriptions`, cmd, this.httpOptions);
  }

  updateSubscription(cmd: UpdateSubscriptionCommand | null): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}subscriptions`, cmd, this.httpOptions);
  }

  deleteSubscription(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}subscriptions/${uid}`, this.httpOptions );
  }

  getUsersInSubscription(uid: string | null): Observable<UserSubscription[] | null> {
    return this.http.get<UserSubscription[] | null>(`${this.apiUrl}subscriptions/users/${uid}`, this.httpOptions );
  }

  addUserToSubscription(addSubscriptionToUserCommand: AddSubscriptionToUserCommand | null): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}subscriptions/add/user`, addSubscriptionToUserCommand, this.httpOptions);
  }

  addUsersToSubscription(addSubscriptionToUserCommands: AddSubscriptionToUserCommand[] | null): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}subscriptions/add/users`, addSubscriptionToUserCommands, this.httpOptions);
  }

  removeUserFromSubscription(removeSubscriptionToUserCommand: RemoveSubscriptionToUserCommand | null): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}subscriptions/remove/user`, removeSubscriptionToUserCommand, this.httpOptions);
  }
}

