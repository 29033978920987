import {CommonModule} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {StructSpinnerService} from '@struct/ui/struct-spinner/struct-spinner.service';
import {UiModule} from '@struct/ui/ui.module';
import {ReplaySubject} from 'rxjs';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {
  AccountsListContentComponent
} from '../../accounts-ui/accounts-list/accounts-list-content/accounts-list-content.component';
import {
  AccountsListEmptyComponent
} from '../../accounts-ui/accounts-list/accounts-list-empty/accounts-list-empty.component';
import {
  AccountsListItemBadgeComponent
} from '../../accounts-ui/accounts-list/accounts-list-item-badge/accounts-list-item-badge.component';
import {
  AccountsListItemDescriptionComponent
} from '../../accounts-ui/accounts-list/accounts-list-item-description/accounts-list-item-description.component';
import {
  AccountsListItemComponent
} from '../../accounts-ui/accounts-list/accounts-list-item/accounts-list-item.component';
import {
  AccountsListSearchComponent
} from '../../accounts-ui/accounts-list/accounts-list-search/accounts-list-search.component';
import {AccountsListComponent} from '../../accounts-ui/accounts-list/accounts-list/accounts-list.component';
import {StructService} from "@struct/models/accountmanagement/domain/structservices";
import {StructServiceApiService} from "@struct/services/account-management";
import { Router } from '@angular/router';

@Component({
  selector: 'accounts-struct-services',
  templateUrl: './struct-services.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsHeaderComponent,
    AccountsListComponent,
    AccountsListSearchComponent,
    AccountsListEmptyComponent,
    AccountsListItemBadgeComponent,
    AccountsListItemDescriptionComponent,
    AccountsListContentComponent,
    AccountsListItemBadgeComponent,
    AccountsListItemComponent,
  ],
})
export class StructServicesComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();

  structServices: StructService[] = [];
  allStructServices: StructService[] = [];
  searchTerm: string;

  constructor(private structServiceApi: StructServiceApiService, private spinnerService: StructSpinnerService, private router : Router) {
    this.structServices = [];
    this.allStructServices = [];
    this.searchTerm = "";
    this.spinnerService.changeShowSpinner(true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }


  ngOnInit(): void {
    this.allStructServices = [];

    this.structServiceApi.getStructServices().subscribe(data => {
      this.allStructServices = data;
      this.search();
      this.spinnerService.changeShowSpinner(false);
    });
  }

  createService(): void {
    this.router.navigate(['/struct-services/create']);
  }

  public searchChanged(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.search();
  }

  public search(): void {
    if (this.searchTerm !== null && this.searchTerm.length > 0) {
      this.structServices = this.allStructServices.filter(
        x =>
          x.name !== null &&
          x.name.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()) !== -1,
      );
    } else {
      this.structServices = Object.assign([], this.allStructServices);
    }
  }
}
