
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportItemsToShow } from "@struct/models/struct/app/domain/fileimport/import-items-to-show";
import { ImportReport } from "@struct/models/struct/app/domain/fileimport/import-report";
import { ExcelDataImportDetails } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/excel-data-import-details";
import { FileImportAnalysis } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/file-import-analysis";
import { ImportSupportedField } from "@struct/models/struct/app/domain/filemapping/import-supported-field";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { ExportImportFieldModel } from "@struct/models/struct/app/frontendapi/models/import/export-import-field-model";
import { GetImportReportRequest } from "@struct/models/struct/app/frontendapi/models/import/get-import-report-request";
import { GlobalListValueImportModel } from "@struct/models/struct/app/frontendapi/models/import/global-list-value-import-model";
import { ImportValidationModel } from "@struct/models/struct/app/frontendapi/models/import/import-validation-model";

@Injectable({ providedIn: "root" })
export class StandardExcelImportApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableImportFieldsByExportDescriptors(itemType: StructEntityType): Observable<ImportSupportedField[]> {
    return this.http.get<ImportSupportedField[]>(`${this.apiUrl}standardexcelimport/availableimportfieldsbyexportdescriptors?itemType=${itemType}`, this.httpOptions );
  }

  getAvailableExportImportFieldsByExportDescriptors(itemType: StructEntityType): Observable<ExportImportFieldModel[]> {
    return this.http.get<ExportImportFieldModel[]>(`${this.apiUrl}standardexcelimport/availableexportimportfieldsbyexportdescriptors?itemType=${itemType}`, this.httpOptions );
  }

  getExcelDataImportDetails(uid: string): Observable<ExcelDataImportDetails> {
    return this.http.get<ExcelDataImportDetails>(`${this.apiUrl}standardexcelimport/exceldataimportdetails?uid=${uid}`, this.httpOptions );
  }

  createGlobalListImportUpdateReport(model: GlobalListValueImportModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/creategloballistimportupdatereport`, model, this.httpOptions);
  }

  createCategoryImportUpdateReport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/createcategoryimportupdatereport`, model, this.httpOptions);
  }

  createProductImportUpdateReport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/createproductimportupdatereport`, model, this.httpOptions);
  }

  createVariantImportUpdateReport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/createvariantimportupdatereport`, model, this.httpOptions);
  }

  createVariantGroupImportUpdateReport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/createvariantgroupimportupdatereport`, model, this.httpOptions);
  }

  getImportReportRecordNosToImport(importReportUid: string, itemsToSelect: ImportItemsToShow): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiUrl}standardexcelimport/importreportrecordnostoimport?importReportUid=${importReportUid}&itemsToSelect=${itemsToSelect}`, this.httpOptions );
  }

  getImportReport(model: GetImportReportRequest): Observable<ImportReport> {
    return this.http.post<ImportReport>(`${this.apiUrl}standardexcelimport/getimportreport`, model, this.httpOptions);
  }

  analyseFileForNewItems(model: ImportValidationModel): Observable<FileImportAnalysis> {
    return this.http.post<FileImportAnalysis>(`${this.apiUrl}standardexcelimport/analysefilefornewitems`, model, this.httpOptions);
  }

  commitGlobalListImport(model: GlobalListValueImportModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/commitgloballistimport`, model, this.httpOptions);
  }

  commitImport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/commitimport`, model, this.httpOptions);
  }
}

