// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStructServicePrice  {
  priceDKK: number;
  priceEUR: number;
  priceUSD: number;
}

export class StructServicePrice  implements IStructServicePrice {
  priceDKK = 0;

  priceEUR = 0;

  priceUSD = 0;


  constructor(data?: Partial<IStructServicePrice>) {
    Object.assign(this, data);
  }
}