
<div *ngIf="showSpinner" class="absolute top-0 left-0 h-full w-full bg-gray-50 z-40 flex justify-center items-center">
  <struct-spinner></struct-spinner>
</div>

<main #appContentContainer class="@container/content-container struct-app-content-container relative flex flex-col flex-grow struct-scrollbar" [ngClass]="noScrolling ? '' : 'overflow-y-auto'">
    <ng-content select="struct-app-pim-header"></ng-content>
    <ng-content select="struct-app-content-header"></ng-content>

    <ng-content></ng-content>
  <div class="absolute inset-0 bg-gray-50 bg-opacity-50 z-10 animate-in fade-in-50 duration-200" *ngIf="disabled"></div>
</main>
