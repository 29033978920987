<struct-app-content-container>
  <accounts-header [headerButtonText]="'Create subscription plan'" (headerButtonClicked)="createSubscriptionPlan()">
    Subscription plans
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <accounts-list>

        <accounts-list-search [placeholder]="'Search subscription plans'" (searchChanged)="searchChanged($event)"></accounts-list-search>

        <accounts-list-content>

          <accounts-list-empty *ngIf="subscriptionPlans?.length === 0 && searchTerm.length > 0">
            No subscription plans matched your search
          </accounts-list-empty>

          <accounts-list-empty *ngIf="subscriptionPlans?.length === 0 && searchTerm.length === 0">
            No subscription plans have been created yet
          </accounts-list-empty>

          <accounts-list-item [iconName]="'subscription_plan'" [routerLink]="'/subscription-plans/' + subscriptionPlan.uid" *ngFor="let subscriptionPlan of subscriptionPlans">
            {{subscriptionPlan.name}}

            <accounts-list-item-badge *ngIf="subscriptionPlan.generallyAvailable" [bgColorClass]="'bg-green-400'">Available to all clients</accounts-list-item-badge>
            <accounts-list-item-badge *ngIf="!subscriptionPlan.generallyAvailable" [bgColorClass]="'bg-gray-400'">Only available to select clients</accounts-list-item-badge>

            <accounts-list-item-description>
              <p class="flex items-center text-sm text-gray-500">
                <struct-icon icon="sell" size="12" class="mr-2"></struct-icon>
                DKK {{subscriptionPlan.configuration!.price!.priceDKK}} / EUR {{subscriptionPlan.configuration!.price!.priceEUR}} / USD {{subscriptionPlan.configuration!.price!.priceUSD}}
              </p>
            </accounts-list-item-description>

          </accounts-list-item>

        </accounts-list-content>

      </accounts-list>
    </struct-app-content-workarea>
  </struct-app-content>
</struct-app-content-container>
