import { Injectable } from "@angular/core";
import { ErrorNotificationComponent } from "./error-notification/error-notification.component";
import { InfoNotificationComponent } from "./info-notification/info-notification.component";
import { NotificationConfig } from "./models/notification-config";
import { NotificationData } from "./models/notification-data";
import { SuccessNotificationComponent } from "@struct/ui/struct-notification/success-notification";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
  })
  export class StructNotificationService {
    constructor(private _snackBar: MatSnackBar) { }

    public showInfoNotification(message:Observable<string>, config: NotificationConfig | null) : void {
        this._snackBar.openFromComponent(InfoNotificationComponent, {
          data: new NotificationData({
            title: message,
            hideCloseButton: config?.hideCloseButton ?? false,
            closeButtonText: config?.closeButtonText ?? 'Close',
            duration: config?.duration ?? 5000,
          }),
          duration: config?.duration ?? 5000,
          horizontalPosition: <MatSnackBarHorizontalPosition>config?.horizontalPosition ?? 'center',
          verticalPosition: <MatSnackBarVerticalPosition>config?.verticalPosition ?? 'top',
          panelClass: 'info-panel',
        });
    }

    public showErrorNotification(message:Observable<string>, config: NotificationConfig | null = null) : void {
        this._snackBar.openFromComponent(ErrorNotificationComponent, {
          data: new NotificationData({
            title: message,
            hideCloseButton: config?.hideCloseButton ?? false,
            closeButtonText: config?.closeButtonText ?? 'Close',
            duration: config?.duration ?? 5000,
          }),
          duration: config?.duration ?? 5000,
          horizontalPosition: <MatSnackBarHorizontalPosition>config?.horizontalPosition ?? 'center',
          verticalPosition: <MatSnackBarVerticalPosition>config?.verticalPosition ?? 'top',
          panelClass: 'error-panel',
        });
    }

    public showSuccessNotification(title:Observable<string>, description:Observable<string> | null = null, config: NotificationConfig | null = null) : void {
        this._snackBar.openFromComponent(SuccessNotificationComponent, {
          data: new NotificationData({
            title: title,
            description: description,
            hideCloseButton: config?.hideCloseButton ?? false,
            closeButtonText: config?.closeButtonText ?? 'Close',
            duration: config?.duration ?? 5000
          }),
          duration: config?.duration ?? 5000,
          horizontalPosition: <MatSnackBarHorizontalPosition>config?.horizontalPosition ?? 'center',
          verticalPosition: <MatSnackBarVerticalPosition>config?.verticalPosition ?? 'top',
          panelClass: 'success-panel',
        });
    }
  }

