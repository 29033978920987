// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AvailableServiceQuota } from "@struct/models/accountmanagement/domain/subscriptions/available-service-quota";
import { StructServicePrice } from "@struct/models/accountmanagement/domain/subscriptions/struct-service-price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISpecialTerms  {
  specialQuotas: AvailableServiceQuota[];
  specialFeatures: string[];
  specialPrice: StructServicePrice;
}

export class SpecialTerms  implements ISpecialTerms {
  specialQuotas: AvailableServiceQuota[] = [];

  specialFeatures: string[] = [];

  specialPrice: StructServicePrice = new StructServicePrice();


  constructor(data?: Partial<ISpecialTerms>) {
    Object.assign(this, data);
  }
}