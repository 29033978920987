
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BulkUpdateModel } from "@struct/models/struct/app/domain/bulkupdate/bulk-update-model";
import { BulkUpdateSupportedField } from "@struct/models/struct/app/domain/bulkupdate/bulk-update-supported-field";
import { MultiItemUpdateModel } from "@struct/models/struct/app/domain/bulkupdate/multi-item-update-model";
import { MultiItemUpdateSettings } from "@struct/models/struct/app/domain/bulkupdate/multi-item-update-settings";
import { UpdateReport } from "@struct/models/struct/app/domain/bulkupdate/update-report";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";

@Injectable({ providedIn: "root" })
export class BulkUpdateApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableFields(entityType: StructEntityType): Observable<BulkUpdateSupportedField[]> {
    return this.http.get<BulkUpdateSupportedField[]>(`${this.apiUrl}bulkupdate/availablefields?entityType=${entityType}`, this.httpOptions );
  }

  getAvailableGlobalListFields(globalListUid: string): Observable<BulkUpdateSupportedField[]> {
    return this.http.get<BulkUpdateSupportedField[]>(`${this.apiUrl}bulkupdate/availablegloballistfields?globalListUid=${globalListUid}`, this.httpOptions );
  }

  bulkUpdateReport(model: BulkUpdateModel): Observable<UpdateReport> {
    return this.http.post<UpdateReport>(`${this.apiUrl}bulkupdate/bulkupdatereport`, model, this.httpOptions);
  }

  globalListBulkUpdateReport(model: BulkUpdateModel): Observable<UpdateReport> {
    return this.http.post<UpdateReport>(`${this.apiUrl}bulkupdate/globallistbulkupdatereport`, model, this.httpOptions);
  }

  bulkUpdateItems(model: BulkUpdateModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}bulkupdate/bulkupdateitems`, model, this.httpOptions);
  }

  bulkUpdateGlobalListItems(model: BulkUpdateModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}bulkupdate/bulkupdateglobalListitems`, model, this.httpOptions);
  }

  saveItemUpdateModel(model: MultiItemUpdateSettings): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}bulkupdate/saveitemupdatemodel`, model, this.httpOptions);
  }

  saveItem(model: MultiItemUpdateModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}bulkupdate/saveitems`, model, this.httpOptions);
  }

  itemUpdateModel(uid: string): Observable<MultiItemUpdateModel> {
    return this.http.get<MultiItemUpdateModel>(`${this.apiUrl}bulkupdate/itemupdatemodel?uid=${uid}`, this.httpOptions );
  }
}

