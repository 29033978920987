
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { ValidationFieldRule } from "@struct/models/struct/app/domain/validation/validation-field-rule";
import { ValidationFileRule } from "@struct/models/struct/app/domain/validation/validation-file-rule";
import { ValidationResultType } from "@struct/models/struct/app/domain/validation/validation-result-type";
import { ValidationRowRule } from "@struct/models/struct/app/domain/validation/validation-row-rule";

@Injectable({ providedIn: "root" })
export class ValidationApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableValidationFieldRules(): Observable<{ [key: string]: ValidationFieldRule[] }> {
    return this.http.get<{ [key: string]: ValidationFieldRule[] }>(`${this.apiUrl}validations/field-rules`, this.httpOptions );
  }

  getAvailableValidationFieldRulesByAttribute(attributeUid: string): Observable<ValidationFieldRule[]> {
    return this.http.get<ValidationFieldRule[]>(`${this.apiUrl}validations/field-rules/${attributeUid}`, this.httpOptions );
  }

  getValidationResultTypes(): Observable<ValidationResultType[]> {
    return this.http.get<ValidationResultType[]>(`${this.apiUrl}validations/result-types`, this.httpOptions );
  }

  getValidationRowRules(itemType: StructEntityType): Observable<ValidationRowRule[]> {
    return this.http.get<ValidationRowRule[]>(`${this.apiUrl}validations/row-rules?itemType=${itemType}`, this.httpOptions );
  }

  getValidationFileRules(itemType: StructEntityType): Observable<ValidationFileRule[]> {
    return this.http.get<ValidationFileRule[]>(`${this.apiUrl}validations/file-rules?itemType=${itemType}`, this.httpOptions );
  }
}

