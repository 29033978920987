import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StructAppSidebarService {
  public saveMenuCollapseStatus(menuAlias: string, collapsed: boolean) {
    localStorage.setItem('StructMenuCollapseStatus_' + menuAlias, JSON.stringify(collapsed));
  }

  public getMenuCollapseStatus(menuAlias: string): boolean {
    const v = localStorage.getItem('StructMenuCollapseStatus_' + menuAlias);
    if (v === null) {
      return false;
    }
    return <boolean>JSON.parse(v);
  }
}
