
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateAttributeCommand } from "@struct/models/struct/app/domain/attributes/commands/attributes/create-attribute-command";
import { UpdateAttributeCommand } from "@struct/models/struct/app/domain/attributes/commands/attributes/update-attribute-command";
import { UpdateAttributeGroupOnAttributesCommand } from "@struct/models/struct/app/domain/attributes/commands/attributes/update-attribute-group-on-attributes-command";
import { UpdateAttributeScopesOnAttributesCommand } from "@struct/models/struct/app/domain/attributes/commands/attributes/update-attribute-scopes-on-attributes-command";
import { AttributeDefinition } from "@struct/models/struct/app/domain/attributes/attribute-definition";
import { AttributeTypeReference } from "@struct/models/struct/app/domain/attributes/attribute-type-reference";
import { DataElementReference } from "@struct/models/struct/app/domain/attributes/data-element-reference";
import { AttributeSetup } from "@struct/models/struct/app/domain/dataconfiguration/implementation/properties/attribute-setup";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { AttributeAliasModel } from "@struct/models/struct/app/frontendapi/models/attribute/attribute-alias-model";
import { AttributeReferenceModel } from "@struct/models/struct/app/frontendapi/models/attribute/attribute-reference-model";
import { AttributeReferenceRequestModel } from "@struct/models/struct/app/frontendapi/models/attribute/attribute-reference-request-model";
import { AttributeSearchModel } from "@struct/models/struct/app/frontendapi/models/attribute/attribute-search-model";

@Injectable({ providedIn: "root" })
export class AttributesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAttributes(languageId: number): Observable<AttributeSearchModel[]> {
    return this.http.get<AttributeSearchModel[]>(`${this.apiUrl}attributes?languageId=${languageId}`, this.httpOptions );
  }

  updateAttributeScopes(command: UpdateAttributeScopesOnAttributesCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}attributes/batch/attributescope`, command, this.httpOptions);
  }

  updateAttributeGroup(command: UpdateAttributeGroupOnAttributesCommand): Observable<AttributeSearchModel[]> {
    return this.http.patch<AttributeSearchModel[]>(`${this.apiUrl}attributes/batch/attributegroup`, command, this.httpOptions);
  }

  getAttributeReferences(model: AttributeReferenceRequestModel): Observable<AttributeReferenceModel[]> {
    return this.http.post<AttributeReferenceModel[]>(`${this.apiUrl}attributes/references`, model, this.httpOptions);
  }

  getAttribute(uid: string): Observable<AttributeDefinition | null> {
    return this.http.get<AttributeDefinition | null>(`${this.apiUrl}attributes/${uid}`, this.httpOptions );
  }

  getAliases(): Observable<AttributeAliasModel[]> {
    return this.http.get<AttributeAliasModel[]>(`${this.apiUrl}attributes/aliases`, this.httpOptions );
  }

  getAttributeTypes(): Observable<AttributeTypeReference[]> {
    return this.http.get<AttributeTypeReference[]>(`${this.apiUrl}attributes/attributetypes`, this.httpOptions );
  }

  getAttributeDataElements(): Observable<DataElementReference[]> {
    return this.http.get<DataElementReference[]>(`${this.apiUrl}attributes/attributedataelements`, this.httpOptions );
  }

  createAttribute(cmd: CreateAttributeCommand): Observable<AttributeDefinition> {
    return this.http.post<AttributeDefinition>(`${this.apiUrl}attributes`, cmd, this.httpOptions);
  }

  updateAttribute(cmd: UpdateAttributeCommand): Observable<AttributeDefinition> {
    return this.http.put<AttributeDefinition>(`${this.apiUrl}attributes`, cmd, this.httpOptions);
  }

  deleteAttribute(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}attributes/${uid}`, this.httpOptions );
  }

  getAttributesByProductStructure(productStructureUid: string, entityType: StructEntityType): Observable<AttributeSetup[]> {
    return this.http.get<AttributeSetup[]>(`${this.apiUrl}attributes/${productStructureUid}/${entityType}`, this.httpOptions );
  }
}

