<struct-app-content-container>
  <struct-app-content-header [title]="'UIComponents.StructTable.SelectColumnsDialog.Title' | translate">
  </struct-app-content-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <div cdkDropListGroup class="grid w-full gap-5 grid-cols-2">
        <div class="w-full bg-gray-50 p-3">
          <div class="w-full h-16 mb-2">
            <form>
                <struct-input inputType="search" (valueChange)="onSearchChanged($event)" [name]="'UIComponents.StructTable.SelectColumnsDialog.AvailableColumns' | translate" [placeholder]="'UIComponents.StructTable.SelectColumnsDialog.SearchAvailableColumns' | translate" appearance="fill">
                  <struct-input-suffix>
                    <struct-icon icon="search"></struct-icon>
                  </struct-input-suffix>
                </struct-input>
              </form>
          </div>

          <div class="w-full">
            <cdk-virtual-scroll-viewport itemSize="48" cdkDropList [cdkDropListData]="shownAvailableColumns" class="border border-gray-100 overflow-y-auto struct-scrollbar overflow-x-clip h-96 bg-white w-full" (cdkDropListDropped)="drop($event, 'available')">
              <div class="py-1 px-3 bg-white/50 border-b border-gray-100 cursor-move flex items-center"  *cdkVirtualFor="let availableColumn of shownAvailableColumns" cdkDrag>
                <struct-icon icon="drag_indicator" class="text-gray-300 mr-2" size="16"></struct-icon>
                <span class="text-sm font-medium">{{availableColumn.title}}</span>
                <struct-icon-button icon="add" size="16" class="ml-auto" (clicked)="addColumn(availableColumn)"></struct-icon-button>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>

        </div>

        <div class="w-full bg-gray-50 p-3">
          <div class="w-full h-16 flex items-center mb-2">
            <span class="font-semibold text-gray-500">Selected columns</span>
          </div>
          <div class="w-full ">
            <div cdkDropList [cdkDropListData]="selectedColumns" class="border border-gray-100 overflow-y-auto struct-scrollbar overflow-x-clip h-96 bg-white w-full" (cdkDropListDropped)="drop($event, 'selected')">
              <div class="py-1 px-3 border-b bg-white/50 border-gray-100 cursor-move flex items-center w-full" *ngFor="let selectedColumn of selectedColumns" cdkDrag>
                <struct-icon icon="drag_indicator" class="text-gray-300 mr-2" size="16"></struct-icon>
                <span class="text-sm font-medium">{{selectedColumn.title}}</span>
                <struct-icon-button icon="delete" size="16" class="ml-auto" (click)="removeColumn(selectedColumn)"></struct-icon-button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <struct-button (click)="cancel()">Cancel</struct-button>
    <struct-button (click)="updateColumns()" color="primary">Update columns</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
