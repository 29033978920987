import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UiModule } from '@struct/ui/ui.module';
import { Observable, ReplaySubject, of, takeUntil } from 'rxjs';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { Currency } from '../models/currency';
import { SubscriptionStatus } from '../models/subscription-status';
import { StructNotificationService, StructSpinnerService } from '@struct/ui/index';
import { MatDialog } from '@angular/material/dialog';
import { AddUserToSubscriptionDialog } from '../add-user-to-subscription-dialog/add-user-to-subscription-dialog.component';
import { nameof } from '@struct/utilities/index';
import { Subscription, SubscriptionPlan, SubscriptionRole, UserSubscription } from '@struct/models/accountmanagement/domain/subscriptions';
import { CountryModel } from '@struct/models/accountmanagement/domain/models';
import { MiscApiService, SubscriptionApiService, SubscriptionPlanApiService } from '@struct/services/account-management';
import { AddSubscriptionToUserCommand, RemoveSubscriptionToUserCommand } from '@struct/models/accountmanagement/domain/subscriptions/commands';
import { User } from '@struct/models/struct/app/domain/usermanagement/user';

@Component({
  selector: 'accounts-subscription-details',
  templateUrl: './subscription-details.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsFormComponent,
    AccountsFormSectionComponent,
    AccountsFormSectionTitleComponent,
    AccountsFormSectionDescriptionComponent,
    AccountsFormSectionContentComponent,
  ],
})
export class SubscriptionDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() subscription: Subscription | null = null;
  @ViewChild('addSubscriptionOwnerForm') subscriptionOwnerForm!: NgForm;

  addSubscriptionOwnerName: string | null = null;
  addSubscriptionOwnerEmail: string | null = null;
  subscriptionPlans$: Observable<SubscriptionPlan[]> | null = null;
  paymentCurrency: string | null = null;
  currencies: Currency[] = [
    { id: 'DKK', name: 'DKK' },
    { id: 'EUR', name: 'EUR' },
    { id: 'USD', name: 'USD' },
  ];

  subscriptionStatusses: SubscriptionStatus[] = [
    { id: 0, name: 'Awaiting approval' },
    { id: 10, name: 'Active' },
    { id: 20, name: 'Suspended' },
    { id: 30, name: 'Terminated' },
  ];

  countries$: Observable<CountryModel[]> | null = null;
  usersInSubscription: UserSubscription[] | null = null;
  usersNotInSubscription: User[] | null = null;
  allUsers: User[] | null = null;
  private destroyed$ = new ReplaySubject();

  constructor(
    private miscApi: MiscApiService,
    private subscriptionPlanApi: SubscriptionPlanApiService,
    private subscriptionApi: SubscriptionApiService,
    private notificationService: StructNotificationService,
    private dialog: MatDialog,
    private spinnerService: StructSpinnerService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.subscriptionPlans$ = this.subscriptionPlanApi.getSubscriptionPlans().pipe(takeUntil(this.destroyed$));
    this.countries$ = this.miscApi.getCountries().pipe(takeUntil(this.destroyed$));
    // this.userApi.getUsers().subscribe(p => {
    //   this.allUsers = p;
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (nameof<this>('subscription') in changes && this.subscription) {
      this.subscriptionApi.getUsersInSubscription(this.subscription.uid).subscribe(p => {
        this.usersInSubscription = p;
        this.usersNotInSubscription = this.allUsers?.filter(user => !this.usersInSubscription?.some(x => x.userUid === user.uid)) ?? null;
      });
    }
  }

  getPlanPrice(plan: SubscriptionPlan): number {
    if (this.subscription !== null) {
      if (this.subscription.paymentCurrency === 'DKK') {
        return plan.configuration?.price?.priceDKK ?? -1;
      } else if (this.subscription.paymentCurrency === 'EUR') {
        return plan.configuration?.price?.priceEUR ?? -1;
      } else if (this.subscription?.paymentCurrency === 'USD') {
        return plan.configuration.price?.priceUSD ?? -1;
      }
    }
    return -1;
  }

  getSubscriptionOwners(): User[] {
    const owners = this.usersInSubscription?.filter(x => x.role === SubscriptionRole.Owner);
    return this.allUsers?.filter(p => owners?.some(q => q.userUid === p.uid)) ?? [];
  }

  getSubscriptionManagers(): User[] {
    const managers = this.usersInSubscription?.filter(x => x.role === SubscriptionRole.Manager);
    return this.allUsers?.filter(p => managers?.some(q => q.userUid === p.uid)) ?? [];
  }

  addSubscriptionOwner() {
    this.addToSubscription(SubscriptionRole.Owner, (user: User) => this.addOwnerToSubscription(user));
  }

  addSubscriptionManager() {
    this.addToSubscription(SubscriptionRole.Manager, (user: User) => this.addManagerToSubscription(user));
  }

  async addSubscriptionToUser(user: User, role: SubscriptionRole) {
    this.spinnerService.changeShowSpinner(true);
    if (user.uid === '') {
      // const u = await firstValueFrom(
      //   this.userApi.createUser(
      //     new CreateUserCommand({
      //       company: user.company,
      //       email: user.email,
      //       firstname: user.firstname,
      //       formatCultureName: user.formatCultureName,
      //       languageName: user.languageName,
      //       lastname: user.lastname,
      //       phone: user.phone,
      //       timeZoneId: user.timeZoneId,
      //       twoFactorEnabled: user.twoFactorEnabled,
      //     })
      //   )
      // );
      // if (!u) {
      //   this.notificationService.showErrorNotification(of('Could not create user'));
      //   return;
      // }
      // this.allUsers?.push(u);
      // user = u;
    }

    this.subscriptionApi
      .addUserToSubscription(
        new AddSubscriptionToUserCommand({
          subscriptionUid: this.subscription?.uid,
          userUid: user.uid,
          role: role,
        })
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        complete: () => {
          if (this.subscription?.uid !== undefined) {
            this.usersNotInSubscription = this.usersNotInSubscription?.filter(usersInSubscription => usersInSubscription.uid !== user.uid) ?? [];
            this.usersInSubscription?.push({ userUid: user.uid, role: role, subscriptionUid: this.subscription.uid });
            this.notificationService.showSuccessNotification(of(`User added to subscription with role ${SubscriptionRole[role]}`));
          }
        },
        error: err => {
          this.notificationService.showErrorNotification(err.error.Message);
        },
        next: () => this.spinnerService.changeShowSpinner(false),
      });
  }

  addToSubscription(role: SubscriptionRole, fn: (user: User) => void) {
    const dialogRef = this.dialog.open(AddUserToSubscriptionDialog, {
      data: { users: this.usersNotInSubscription, subscription: this.subscription, role: role },
      width: '500px',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe((result: User[]) => {
      if (result) {
        result.map(user => fn(user));
      }
    });
  }

  addOwnerToSubscription(user: User): void {
    this.addSubscriptionToUser(user, SubscriptionRole.Owner);
  }

  addManagerToSubscription(user: User): void {
    this.addSubscriptionToUser(user, SubscriptionRole.Manager);
  }

  removeFromSubscription(user: User) {
    this.spinnerService.changeShowSpinner(true);
    this.subscriptionApi
      .removeUserFromSubscription(
        new RemoveSubscriptionToUserCommand({
          subscriptionUid: this.subscription?.uid,
          userUid: user.uid,
        })
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        complete: () => {
          this.usersInSubscription = this.usersInSubscription?.filter(usersInSubscription => usersInSubscription.userUid !== user.uid) ?? [];
          this.usersNotInSubscription?.push(user);
          this.notificationService.showSuccessNotification(of(`User removed from subscription`));
        },
        error: err => {
          this.notificationService.showErrorNotification(err.error.Message);
        },
        next: () => this.spinnerService.changeShowSpinner(false),
      });
  }

  selectSubscriptionPlan(subscriptionPlanUid: string | null): void {
    if (this.subscription && subscriptionPlanUid !== null) {
      this.subscription.subscriptionPlanUid = subscriptionPlanUid;
    }
  }

  resendEmailVerification(user: User) {
    this.spinnerService.changeShowSpinner(true);
    // this.userApi
    //   .sendVerificationMail(user.uid)
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe({
    //     complete: () => {
    //       user.enableResendEmailVerification = false;
    //       this.notificationService.showSuccessNotification(of(`Verification email sent`));
    //     },
    //     error: (err : any) => {
    //       this.notificationService.showErrorNotification(err.error.Message);
    //     },
    //     next: () => this.spinnerService.changeShowSpinner(false),
    //   });
  }
}
