// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AvailableStructService } from "@struct/models/accountmanagement/domain/subscriptions/available-struct-service";
import { StructServicePrice } from "@struct/models/accountmanagement/domain/subscriptions/struct-service-price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscriptionPlanConfiguration  {
  price: StructServicePrice;
  availableServices: AvailableStructService[];
}

export class SubscriptionPlanConfiguration  implements ISubscriptionPlanConfiguration {
  price: StructServicePrice = new StructServicePrice();

  availableServices: AvailableStructService[] = [];


  constructor(data?: Partial<ISubscriptionPlanConfiguration>) {
    Object.assign(this, data);
  }
}