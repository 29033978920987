
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnqueuedTaskInfo } from "@struct/models/struct/app/domain/backgroundtask/enqueued-task-info";
import { BackgroundTaskListModel } from "@struct/models/struct/app/frontendapi/models/backgroundtask/background-task-list-model";

@Injectable({ providedIn: "root" })
export class BackgroundTaskApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getBackgroundTasks(page: number, pageSize: number): Observable<BackgroundTaskListModel> {
    return this.http.get<BackgroundTaskListModel>(`${this.apiUrl}backgroundtask/getbackgroundtasks?page=${page}&pageSize=${pageSize}`, this.httpOptions );
  }

  getMyBackgroundTasks(page: number, pageSize: number): Observable<BackgroundTaskListModel> {
    return this.http.get<BackgroundTaskListModel>(`${this.apiUrl}backgroundtask/getmybackgroundtasks?page=${page}&pageSize=${pageSize}`, this.httpOptions );
  }

  getBackgroundTask(taskUid: string): Observable<EnqueuedTaskInfo> {
    return this.http.get<EnqueuedTaskInfo>(`${this.apiUrl}backgroundtask/getbackgroundtask?taskUid=${taskUid}`, this.httpOptions );
  }

  restartTask(taskUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}backgroundtask/restarttask?taskUid=${taskUid}`, null, this.httpOptions);
  }
}

