
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VariationDefinitionReference } from "@struct/models/struct/app/domain/dataconfiguration/variation-definition-reference";
import { WizardEditorModel } from "@struct/models/struct/app/domain/dataconfiguration/wizard-editor-model";
import { EditorBreadcrumb } from "@struct/models/struct/app/domain/editor/editor-breadcrumb";
import { ProductEditorModel } from "@struct/models/struct/app/domain/editor/product-editor-model";
import { ArchiveProductsCommand } from "@struct/models/struct/app/domain/products/commands/archive-products-command";
import { ChangeProductStructureCommand } from "@struct/models/struct/app/domain/products/commands/change-product-structure-command";
import { CopyProductCommand } from "@struct/models/struct/app/domain/products/commands/copy-product-command";
import { DeleteProductsCommand } from "@struct/models/struct/app/domain/products/commands/delete-products-command";
import { MergeProductsCommand } from "@struct/models/struct/app/domain/products/commands/merge-products-command";
import { UnarchiveProductsCommand } from "@struct/models/struct/app/domain/products/commands/unarchive-products-command";
import { SimpleProduct } from "@struct/models/struct/app/domain/products/simple-product";
import { SaveTabModel } from "@struct/models/struct/app/frontendapi/models/editormodel/save-tab-model";
import { StructEntityUpdatedModel } from "@struct/models/struct/app/frontendapi/models/editormodel/struct-entity-updated-model";
import { CreateProductModel } from "@struct/models/struct/app/frontendapi/models/products/create-product-model";
import { ProductStructureChangeInfo } from "@struct/models/struct/app/frontendapi/models/search/product-structure-change-info";

@Injectable({ providedIn: "root" })
export class ProductsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getEditorModel(productId: number): Observable<ProductEditorModel> {
    return this.http.get<ProductEditorModel>(`${this.apiUrl}products/${productId}`, this.httpOptions );
  }

  getCreateEditorModel(productStructureUid: string, categoryId: number | null): Observable<WizardEditorModel> {
    return this.http.get<WizardEditorModel>(`${this.apiUrl}products/create/${productStructureUid}?categoryId=${categoryId}`, this.httpOptions );
  }

  getVariationDefinitions(productStructureUid: string): Observable<VariationDefinitionReference[]> {
    return this.http.get<VariationDefinitionReference[]>(`${this.apiUrl}products/${productStructureUid}/variationdefinitions`, this.httpOptions );
  }

  getAvailableVariationDefinitions(productIds: number[]): Observable<VariationDefinitionReference[]> {
    return this.http.post<VariationDefinitionReference[]>(`${this.apiUrl}products/availablevariationdefinitions`, productIds, this.httpOptions);
  }

  getProductToProductStructureMap(productIds: number[]): Observable<{ [key: number]: string }> {
    return this.http.post<{ [key: number]: string }>(`${this.apiUrl}products/producttoproductstructuremap`, productIds, this.httpOptions);
  }

  deleteProduct(model: DeleteProductsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}products/delete`, model, this.httpOptions);
  }

  archiveProduct(model: ArchiveProductsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}products/archive`, model, this.httpOptions);
  }

  unArchiveProduct(model: UnarchiveProductsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}products/unarchive`, model, this.httpOptions);
  }

  copyProduct(model: CopyProductCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}products/copy`, model, this.httpOptions);
  }

  mergeProduct(model: MergeProductsCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}products/merge`, model, this.httpOptions);
  }

  updateClassifications(model: any): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}products/updateclassifications?model=${model}`, null, this.httpOptions);
  }

  getSimpleProducts(productIds: number[]): Observable<SimpleProduct[]> {
    return this.http.post<SimpleProduct[]>(`${this.apiUrl}products/simpleproducts`, productIds, this.httpOptions);
  }

  getProductBreadcrumb(productId: number): Observable<EditorBreadcrumb> {
    return this.http.get<EditorBreadcrumb>(`${this.apiUrl}products/${productId}/breadcrumb`, this.httpOptions );
  }

  getProductStructureChangeInfo(model: ChangeProductStructureCommand): Observable<ProductStructureChangeInfo> {
    return this.http.patch<ProductStructureChangeInfo>(`${this.apiUrl}products/getproductstructurechangeinfo`, model, this.httpOptions);
  }

  hasProductStructureVariants(productId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}products/hasproductstructurevariants?productId=${productId}`, this.httpOptions );
  }

  hasProductStructureVariantGroups(productId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}products/hasproductstructurevariantsgroups?productId=${productId}`, this.httpOptions );
  }

  changeProductStructure(model: ChangeProductStructureCommand): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}products/changeproductstructure`, model, this.httpOptions);
  }

  createProduct(model: CreateProductModel): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}products/create`, model, this.httpOptions);
  }

  saveTab(model: SaveTabModel): Observable<StructEntityUpdatedModel> {
    return this.http.patch<StructEntityUpdatedModel>(`${this.apiUrl}products/savetab`, model, this.httpOptions);
  }
}

