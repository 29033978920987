import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UiModule } from '@struct/ui/ui.module';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { SubscriptionPlanFeatureViewModel } from './SubscriptionPlanFeatureViewModel';
import { SubscriptionPlanQuotaViewModel } from './SubscriptionPlanQuotaViewModel';
import { SubscriptionPlanServiceViewModel } from './SubscriptionPlanServiceViewModel';
import {StructServiceApiService} from "@struct/services/account-management";
import {
  AvailableServiceQuota,
  AvailableStructService, StructServicePrice,
  SubscriptionPlan, SubscriptionPlanConfiguration
} from "@struct/models/accountmanagement/domain/subscriptions";
import {StructService} from "@struct/models/accountmanagement/domain/structservices";

@Component({
  selector: 'accounts-subscription-plan-details',
  templateUrl: './subscription-plan-details.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsFormComponent,
    AccountsFormSectionComponent,
    AccountsFormSectionTitleComponent,
    AccountsFormSectionDescriptionComponent,
    AccountsFormSectionContentComponent,
  ],
})
export class SubscriptionPlanDetailsComponent implements OnInit, OnDestroy {
  @Input() subscriptionPlan: SubscriptionPlan;

  structServices: StructService[] = [];
  serviceViewModels: SubscriptionPlanServiceViewModel[] = [];

  private destroyed$ = new ReplaySubject();

  constructor(private structServicesApi: StructServiceApiService) {
    this.subscriptionPlan = new SubscriptionPlan();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.structServicesApi
      .getStructServices()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(x => {
        this.structServices = x;
        this.buildViewModel();
      });
  }

  public toggleService(service: SubscriptionPlanServiceViewModel): void {
    service.available = !service.available;
  }

  public prepareSubscriptionplanForSubmission(): void {
    this.subscriptionPlan.configuration.availableServices = [];
    this.serviceViewModels.forEach(x => {
      if (x.available) {
        const quotas: AvailableServiceQuota[] = [];
        const features: string[] = [];

        x.features.forEach(f => {
          if (f.selected && f.alias) {
            features.push(f.alias);
          }
        });

        x.quotas.forEach(q => {
          if (q.amountPerUnitPrice && q.includedAmount) {
            quotas.push(
              new AvailableServiceQuota({
                alias: q.alias,
                amountPerUnitPrice: q.amountPerUnitPrice,
                includedAmount: q.includedAmount,
                unitPrice: q.unitPrice,
              })
            );
          }
        });

        if (this.subscriptionPlan.configuration.availableServices === null) {
          this.subscriptionPlan.configuration.availableServices = [];
        }

        this.subscriptionPlan.configuration.availableServices.push(
          new AvailableStructService({
            alias: x.alias,
            price: x.price,
            activeFeatures: features,
            quotas: quotas,
          })
        );
      }
    });
  }

  private buildViewModel() {
    if (this.subscriptionPlan.configuration === null) {
      this.subscriptionPlan.configuration = new SubscriptionPlanConfiguration();
    }

    if (this.subscriptionPlan.configuration.availableServices === null) {
      this.subscriptionPlan.configuration.availableServices = [];
    }

    this.structServices.forEach(s => {
      const availableService = this.subscriptionPlan.configuration.availableServices.find((x) => x.alias === s.alias);
      const features: SubscriptionPlanFeatureViewModel[] = [];
      const quotas: SubscriptionPlanQuotaViewModel[] = [];
      let activeFeatures: Array<string> = [];
      let fulfilledQuotas: Array<AvailableServiceQuota> = [];

      if (availableService?.activeFeatures) {
        activeFeatures = availableService.activeFeatures;
      }
      if (availableService?.quotas) {
        fulfilledQuotas = availableService.quotas;
      }

      if (s.features !== null) {
        s.features.forEach((f) => {
          const isSelected = activeFeatures.indexOf(f.alias) !== -1;
          features.push(
            new SubscriptionPlanFeatureViewModel({
              alias: f.alias,
              name: f.name,
              description: f.description,
              sortOrder: f.sortOrder,
              selected: isSelected,
            })
          );
        });
      }

      if (s.quotas !== null) {
        s.quotas.forEach((q) => {
          const quota = fulfilledQuotas.find(x => x.alias === q.alias);
          quotas.push(
            new SubscriptionPlanQuotaViewModel({
              alias: q.alias,
              name: q.name,
              description: q.description,
              amountUnit: q.amountUnit,
              includedAmount: quota?.includedAmount,
              amountPerUnitPrice: quota?.amountPerUnitPrice,
              unitPrice: quota?.unitPrice ? quota.unitPrice : new StructServicePrice(),
            })
          );
        });
      }

      this.serviceViewModels.push(
        new SubscriptionPlanServiceViewModel({
          alias: s.alias,
          name: s.name,
          description: s.description,
          available: availableService !== null,
          price: availableService?.price ? availableService.price : new StructServicePrice(),
          features: features,
          quotas: quotas,
        })
      );
    });
  }
}
