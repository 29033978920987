
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataImportDetails } from "@struct/models/struct/app/domain/fileimport/data-import-details";
import { ImporterInfo } from "@struct/models/struct/app/domain/fileimport/importer-info";
import { ImportTransformation } from "@struct/models/struct/app/domain/filemapping/importtransformations/import-transformation";
import { FileUploadModel } from "@struct/models/struct/app/frontendapi/models/import/file-upload-model";

@Injectable({ providedIn: "root" })
export class ImportApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableTransformations(): Observable<{ [key: string]: ImportTransformation[] }> {
    return this.http.get<{ [key: string]: ImportTransformation[] }>(`${this.apiUrl}import/transformations`, this.httpOptions );
  }

  getAvailableTransformationsByMappedFieldUid(mappedFieldUid: string): Observable<ImportTransformation[]> {
    return this.http.get<ImportTransformation[]>(`${this.apiUrl}import/transformations/${mappedFieldUid}`, this.httpOptions );
  }

  getMedia(dataImportUid: string, mediaName: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}import/media?dataImportUid=${dataImportUid}&mediaName=${encodeURIComponent(mediaName)}`, {responseType:'blob' as 'json', withCredentials: true} );
  }

  downloadMedia(dataImportUid: string, mediaName: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}import/downloadmedia?dataImportUid=${dataImportUid}&mediaName=${encodeURIComponent(mediaName)}`, null, {responseType:'blob' as 'json', withCredentials: true});
  }

  downloadFile(dataImportUid: string, fileUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}import/downloadfile?dataImportUid=${dataImportUid}&fileUid=${fileUid}`, null, {responseType:'blob' as 'json', withCredentials: true});
  }

  getDataImport(uid: string): Observable<DataImportDetails> {
    return this.http.get<DataImportDetails>(`${this.apiUrl}import/dataimport?uid=${uid}`, this.httpOptions );
  }

  getImporters(): Observable<ImporterInfo[]> {
    return this.http.get<ImporterInfo[]>(`${this.apiUrl}import/importers`, this.httpOptions );
  }

  upload(importerReference: string, model: FileUploadModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}import/upload?importerReference=${encodeURIComponent(importerReference)}`, model, this.httpOptions);
  }
}

