
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SetTextGenerationApprovalCommand } from "@struct/models/struct/app/domain/aiassistant/commands/set-text-generation-approval-command";
import { SetTranslationApprovalCommand } from "@struct/models/struct/app/domain/aiassistant/commands/set-translation-approval-command";
import { UpdateAiAssistantSettingsCommand } from "@struct/models/struct/app/domain/aiassistant/commands/update-ai-assistant-settings-command";
import { AIAssistantSettings } from "@struct/models/struct/app/domain/aiassistant/aI-assistant-settings";
import { AITextGenerationChatResponseModel } from "@struct/models/struct/app/domain/aiassistant/aI-text-generation-chat-response-model";
import { AttributeAICommand } from "@struct/models/struct/app/domain/aiassistant/attribute-aI-command";
import { StructEntityGeneratedText } from "@struct/models/struct/app/domain/aiassistant/struct-entity-generated-text";
import { TextGenerationTaskInfo } from "@struct/models/struct/app/domain/aiassistant/text-generation-task-info";
import { TextTranslationTaskInfo } from "@struct/models/struct/app/domain/aiassistant/text-translation-task-info";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { BulkCommandModel } from "@struct/models/struct/app/frontendapi/models/aiassistant/bulk-command-model";
import { DataElementValuesRequestModel } from "@struct/models/struct/app/frontendapi/models/aiassistant/data-element-values-request-model";
import { GenerateChatResponseModel } from "@struct/models/struct/app/frontendapi/models/aiassistant/generate-chat-response-model";
import { TechnicalSpecificationsRequestModel } from "@struct/models/struct/app/frontendapi/models/aiassistant/technical-specifications-request-model";
import { TextResultModel } from "@struct/models/struct/app/frontendapi/models/aiassistant/text-result-model";
import { UpdateGeneratedTextCommand } from "@struct/models/struct/app/frontendapi/models/aiassistant/update-generated-text-command";

@Injectable({ providedIn: "root" })
export class AIAssistantApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  isTranslationEnabled(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}aiassistant/isTranslationEnabled`, this.httpOptions );
  }

  getSettings(): Observable<AIAssistantSettings | null> {
    return this.http.get<AIAssistantSettings | null>(`${this.apiUrl}aiassistant/settings`, this.httpOptions );
  }

  updateSettings(settingsCommand: UpdateAiAssistantSettingsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}aiassistant/settings`, settingsCommand, this.httpOptions);
  }

  getTextGenerationTaskInfo(taskUid: string): Observable<TextGenerationTaskInfo> {
    return this.http.get<TextGenerationTaskInfo>(`${this.apiUrl}aiassistant/gettextgenerationtaskinfo?taskUid=${taskUid}`, this.httpOptions );
  }

  setTextGenerationApproval(cmd: SetTextGenerationApprovalCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}aiassistant/settextgenerationapproval`, cmd, this.httpOptions);
  }

  approveAllTextGenerations(taskUid: string): Observable<TextGenerationTaskInfo> {
    return this.http.post<TextGenerationTaskInfo>(`${this.apiUrl}aiassistant/approvealltextgenerations?taskUid=${taskUid}`, null, this.httpOptions);
  }

  declineAllTextGenerations(taskUid: string): Observable<TextGenerationTaskInfo> {
    return this.http.post<TextGenerationTaskInfo>(`${this.apiUrl}aiassistant/declinealltextgenerations?taskUid=${taskUid}`, null, this.httpOptions);
  }

  updateGeneratedText(model: UpdateGeneratedTextCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}aiassistant/updategeneratedtext`, model, this.httpOptions);
  }

  saveApprovedTextGenerations(taskUid: string): Observable<TextGenerationTaskInfo> {
    return this.http.post<TextGenerationTaskInfo>(`${this.apiUrl}aiassistant/saveapprovedtextgenerations?taskUid=${taskUid}`, null, this.httpOptions);
  }

  setTranslationApproval(cmd: SetTranslationApprovalCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}aiassistant/settranslationapproval`, cmd, this.httpOptions);
  }

  approveAllTranslations(taskUid: string): Observable<TextTranslationTaskInfo> {
    return this.http.post<TextTranslationTaskInfo>(`${this.apiUrl}aiassistant/approvealltranslations?taskUid=${taskUid}`, null, this.httpOptions);
  }

  declineAllTranslations(taskUid: string): Observable<TextTranslationTaskInfo> {
    return this.http.post<TextTranslationTaskInfo>(`${this.apiUrl}aiassistant/declinealltranslations?taskUid=${taskUid}`, null, this.httpOptions);
  }

  saveApprovedTranslations(taskUid: string): Observable<TextTranslationTaskInfo> {
    return this.http.post<TextTranslationTaskInfo>(`${this.apiUrl}aiassistant/saveapprovedtranslations?taskUid=${taskUid}`, null, this.httpOptions);
  }

  getTaskGeneratedTexts(taskUid: string, page: number, pageSize: number): Observable<StructEntityGeneratedText[]> {
    return this.http.get<StructEntityGeneratedText[]>(`${this.apiUrl}aiassistant/gettaskgeneratedtexts?taskUid=${taskUid}&page=${page}&pageSize=${pageSize}`, this.httpOptions );
  }

  getTextTranslationTaskInfo(taskUid: string): Observable<TextTranslationTaskInfo> {
    return this.http.get<TextTranslationTaskInfo>(`${this.apiUrl}aiassistant/gettexttranslationtaskinfo?taskUid=${taskUid}`, this.httpOptions );
  }

  getAvailableAICommands(entityType: StructEntityType): Observable<AttributeAICommand[]> {
    return this.http.get<AttributeAICommand[]>(`${this.apiUrl}aiassistant/availableaicommands?entityType=${entityType}`, this.httpOptions );
  }

  executeBulkCommand(model: BulkCommandModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}aiassistant/executebulkcommand`, model, this.httpOptions);
  }

  generateChatResponse(model: GenerateChatResponseModel): Observable<AITextGenerationChatResponseModel> {
    return this.http.post<AITextGenerationChatResponseModel>(`${this.apiUrl}aiassistant/generatechatresponse`, model, this.httpOptions);
  }

  getTechnicalSpecifications(requestModel: TechnicalSpecificationsRequestModel): Observable<TextResultModel> {
    return this.http.post<TextResultModel>(`${this.apiUrl}aiassistant/gettechnicalspecifications`, requestModel, this.httpOptions);
  }

  getTextAttributeData(requestModel: DataElementValuesRequestModel): Observable<TextResultModel> {
    return this.http.post<TextResultModel>(`${this.apiUrl}aiassistant/gettextattributedata`, requestModel, this.httpOptions);
  }
}

