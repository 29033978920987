import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/internal/Observable";
import {of, tap} from "rxjs";
import {StructNotificationService} from "@struct/ui/struct-notification/struct-notification.service";
import {ErrorDetailsModel} from "@struct/models/struct/shared/models";
import { Router } from "@angular/router";


@Injectable()
export class ApiErrorHandler implements HttpInterceptor {

    constructor(private _notificationService: StructNotificationService, private router: Router) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //handle errors globally
        return next.handle(req).pipe(
            tap({
                error: (err: any | ErrorDetailsModel) => {
                    if(err instanceof HttpErrorResponse && err.status === 401){
                            console.log('Unauthorized');
                    }
                    else if(err instanceof HttpErrorResponse && err.status === 403){
                        console.log('Access denied');
                    }
                    // Todo: Handle this error based on the env. For dev/testing, we can show the error message. For prod, we can show a generic error message and log to the log provider
                    else if (err.error) {
                        if (err.error.IsDomainException) {
                            this._notificationService.showErrorNotification(of(err.error.Message ?? "An unknown error occurred"));
                        } else {
                            this._notificationService.showErrorNotification(of(err.error.error ?? 'An unknown error occurred'));
                        }
                    } else {
                        this._notificationService.showErrorNotification(of(err.error?.error ?? 'An unknown error occurred'));
                    }
                },
                next: x => x
            })
        );
    }
}
