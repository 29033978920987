
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateAttributeScopeCommand } from "@struct/models/struct/app/domain/attributes/commands/attributescopes/create-attribute-scope-command";
import { UpdateAttributeScopeCommand } from "@struct/models/struct/app/domain/attributes/commands/attributescopes/update-attribute-scope-command";
import { AttributeScope } from "@struct/models/struct/app/domain/attributes/attribute-scope";

@Injectable({ providedIn: "root" })
export class AttributeScopesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAttributeScopes(): Observable<AttributeScope[]> {
    return this.http.get<AttributeScope[]>(`${this.apiUrl}attributescopes`, this.httpOptions );
  }

  getAttributeScope(uid: string): Observable<AttributeScope> {
    return this.http.get<AttributeScope>(`${this.apiUrl}attributescopes/${uid}`, this.httpOptions );
  }

  getAttributes(uid: string): Observable<AttributeScope[]> {
    return this.http.get<AttributeScope[]>(`${this.apiUrl}attributescopes/${uid}/attributes`, this.httpOptions );
  }

  createAttributeScope(cmd: CreateAttributeScopeCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}attributescopes`, cmd, this.httpOptions);
  }

  updateAttributeScope(cmd: UpdateAttributeScopeCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}attributescopes`, cmd, this.httpOptions);
  }

  deleteAttributeScope(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}attributescopes/${uid}`, this.httpOptions );
  }
}

