<struct-app-content-container>
  <accounts-header [goBackToRoute]="'/subscription-plans'" [headerButtonText]="'Delete subscription plan'" [headerButtonIsDelete]="true" (headerButtonClicked)="deleteSubscriptionPlan()">
    Edit subscription plan
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #subscriptionPlanform="ngForm">
        <accounts-subscription-plan-details #subscriptionPlanDetails [subscriptionPlan]="subscriptionPlan"></accounts-subscription-plan-details>
        <!-- <accounts-form-danger-zone
        [title]="'Delete this subscription plan'"
        [description]="'Only delete the plan if you are 100% certain it is not used anywhere'"
        (onDeleteClicked)="deleteSubscriptionPlan()"
        [buttonText]="'Delete subscription plan'">
        </accounts-form-danger-zone> -->
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button color="primary" buttonType="submit" (click)="submitSubscriptionPlan()">Update subscription plan</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
