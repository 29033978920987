<struct-app-content-container>
  <accounts-header [headerButtonText]="'Create service'" (headerButtonClicked)="createService()">
    Services
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <accounts-list>

        <accounts-list-search [placeholder]="'Search services'" (searchChanged)="searchChanged($event)"></accounts-list-search>

        <accounts-list-content>

          <accounts-list-empty *ngIf="structServices?.length === 0 && searchTerm.length > 0" >
            No services matched your search
          </accounts-list-empty>

          <accounts-list-empty *ngIf="structServices?.length === 0 && searchTerm.length === 0">
            No services have been created yet
          </accounts-list-empty>

          <accounts-list-item [iconName]="'service'" [routerLink]="'/services/' + structService.alias" *ngFor="let structService of structServices">
            {{structService.name}}
          </accounts-list-item>

        </accounts-list-content>

      </accounts-list>
    </struct-app-content-workarea>
  </struct-app-content>
</struct-app-content-container>
